import { getServiceUrl } from '../base/utils.js';
import { $_PAGE_ } from '../base/vars.js';
let getProfilePromise = null;

class ProfileHandler {
    async getProfile(getNewRequest) {
        if (!getNewRequest && getProfilePromise) {
            return getProfilePromise;
        }
        getProfilePromise = new Promise(async(resolve, reject) => {
            $.ajax({
                type: 'GET',
                url: this.generateEndpoint(),
                success: (result) => {
                  resolve(result);
                },
                error: (result, status, error) => {
                  reject(result, status, error);
                }
            });
        });
        return getProfilePromise;
    }
    
    generateEndpoint() {
        const portal = window.brand_id;
        const fullProfile = 
            '&includeAddresses=true' + 
            '&includeCurrencies=true' +
            '&includeAliases=true' +
            '&includeEmails=true' + 
            '&includePhones=true' + 
            '&includePreferences=true' +
            '&includePaymentCardAccounts=true';
        const lessProfile = 
            '&includeAddresses=true' +
            '&includeCurrencies=true' +
            '&includeAliases=true';
        const includePreferences = 
            '&includePreferences=true';
        const includeTiers = 
            '&includeRedemptions=false' +
            '&includeTiers=true';
        const includeRedemptionsTiersPoints = 
            '&includeRedemptions=true' +
            '&includeTiers=true' +
            '&includePointExpiry=true';
        let endpointUrl = getServiceUrl('getProfileBase');

        if (portal !== 'wr') {
            endpointUrl += ($_PAGE_.is('.booking-page') ? fullProfile : lessProfile) + includeTiers;
        } else {
            endpointUrl += ($_PAGE_.is('.my-profile-template') ? fullProfile : (lessProfile + includePreferences)) + includeRedemptionsTiersPoints;
        }
        return endpointUrl;
    }
}

export default new ProfileHandler();
