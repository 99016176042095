// RESERVATION HANDLER
import {
  _isNotNull,
  _isNumber,
  formatDateForPrinting,
  formatNumber,
  formatTimeShow,
  getCurrencyMapping,
  getDateFromDateString,
  getNumDays,
  getSecureHostedDomainUrl,
  getServiceUrl,
  handlePostSubtotal,
  isGoFreeRate
} from "./utils";

class ReservationFacade {
  constructor() {
  }

  retrieveReservation(firstName, lastName, confirmation, brand, language) {
      const params = {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        confirmation: confirmation.trim(),
        hotelBrand: brand,
        language: language
      };

    return new Promise((resolve, reject) => {
      $.ajax({
        url: getSecureHostedDomainUrl() + getServiceUrl('retrieveReservation'),
        data: params,
        type: 'POST',
        success: (res) => {
          const {status: status, retrieve: reservation = {}} = res;
          if (res && status === 'OK' && reservation) {
            resolve(this.mapResponseToReservation(reservation));
          } else {
            resolve({});
          }
        },
        error: (error) => {
          reject(error);
        }
      });
    });
  }

  mapResponseToReservation(res) {
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);

    const {confirmation: confirmation, cancellable: cancellable, modifiable: modifiable, cancellation: cancellation,
      reservationChannel: reservationChannel, directBillNumber: directBillNumber, directBillProjectNumber: directBillProjectNumber, directBillVCCExpiryDate: directBillVCCExpiryDate,
      rooms: [{CheckInDate: checkInDateString, CheckOutDate: checkOutDateString, TotalAfterTax: totalAfterTax, TotalBeforeTax: totalBeforeTax,
      CurrencyCode: currency, taxInfo: taxInfo, taxList: taxListing, dateRateMap: dateRateMapping, roomTypeDescription: roomTypeDescription, Units: roomUnits,
        nAdults: adults, nChildren: children, checkInTime: checkInTime = '', checkOutTime: checkOutTime = '', Description: description, RatePlanCode: ratePlanCode, points: points,
        cancelPolicyDesc: cancelPolicyDesc, depositPolicy: depositPolicy, roomTypeCode: roomTypeCode, ratePlanName: ratePlanName} = {}],
      property: {propertyId: propertyId, brand: brand, brandTier: brandTier = '', name: propertyName, address1: propertyAddress1, address2: propertyAddress2, address3: propertyAddress3, city: propertyCity, stateName: propertyState, countryName: propertyCountry, hotelPostalCode: propertyPostalCode, phone1: propertyPhone = ''} = {},
      customer: {FirstName: customerFirstName, LastName: customerLastName, City: customerCity, State: customerState, Country: customerCountry, PostalCode: customerPostalCode, PhoneNumber: customerPhone, Email: customerEmail, Comments: comments, customerWRN: customerRewardsNumber} = {}} = res;


    const checkInDate = getDateFromDateString(checkInDateString);
    const checkOutDate = getDateFromDateString(checkOutDateString);
    const nights = getNumDays(checkInDate, checkOutDate);
    const giftCardPrice = (_isNotNull(comments)) ? parseFloat(comments.split('-')[1]) : '';
    const taxTotal = parseFloat(totalAfterTax - totalBeforeTax).toFixed(2);
    const taxList = (_isNotNull(taxInfo)) ? taxInfo : JSON.stringify(taxListing);
    const dateRateMap = (_isNotNull(dateRateMapping)) ? (JSON.stringify(dateRateMapping)) : '';
    const dateTaxAmountMap = (_isNotNull(dateTaxAmountMap)) ? (JSON.stringify(dateTaxAmountMap)) : '';
    const dateRateFeeMap = (_isNotNull(dateRateFeeMap)) ? (JSON.stringify(dateRateFeeMap)) : '';
    const addressString = `${propertyAddress1}${(propertyAddress2 ? ' ' + propertyAddress2 : '')}${(propertyAddress3 ? ' ' + propertyAddress3 : '')} , ${propertyCity}, ${(propertyState ? propertyState : propertyCountry)} ${propertyPostalCode}`;

    return {
      propertyName: propertyName,
      propertyAddress: addressString,
      propertyPhone: propertyPhone,
      propertyCity: propertyCity,
      confirmationNumber: confirmation,
      confirmationNumbers: confirmation,
      firstName: customerFirstName,
      lastName: customerLastName,
      roomDescriptionLong: roomTypeDescription,
      rooms: roomUnits,
      nights: nights,
      adults: adults,
      children: children,
      checkInDateFormatted: formatDateForPrinting(checkInDate, 'weekdayCompact'),
      checkInDate: checkInDateString,
      checkOutDateFormatted: formatDateForPrinting(checkOutDate, 'weekdayCompact'),
      checkOutDate: checkOutDateString,
      checkInTime: _isNotNull(checkInTime) ? formatTimeShow(checkInTime) : '',
      checkOutTime: _isNotNull(checkOutTime) ? formatTimeShow(checkOutTime) : '',
      roomDescriptionShort: description,
      subtotal: handlePostSubtotal(totalBeforeTax, giftCardPrice, currency, ratePlanCode),
      taxTotal: getCurrencyMapping(currency) + taxTotal + ' ' + currency,
      taxList: taxList,
      totalCash: !(isGoFreeRate(ratePlanCode)) ? getCurrencyMapping(currency) + totalAfterTax + ' ' + currency : '',
      totalPoints: formatNumber(points || ''),
      cancellationPolicy: cancelPolicyDesc,
      depositPolicy: depositPolicy,
      giftCard: _isNumber(giftCardPrice) ? getCurrencyMapping(currency) + giftCardPrice.toFixed(2) + ' ' + currency : '',
      propertyId: propertyId,
      roomType: roomTypeCode,
      brandId: brand,
      brandTier: brandTier,
      currencyCode: currency,
      rateCode: ratePlanCode,
      ratePlanName: ratePlanName ? ratePlanName : '',
      roomCode: roomTypeCode,
      cancellable: cancellable,
      modifiable: (getDateFromDateString(todayDate) <= getDateFromDateString(checkInDate)) ? modifiable : 'N',
      cancelled: cancellation,
      email: customerEmail,
      comments: comments,
      customerCity: customerCity,
      customerState: customerState,
      customerCountry: customerCountry,
      customerZip: customerPostalCode,
      customerPhoneNum: customerPhone,
      customerRewardsNumber: customerRewardsNumber,
      reservationChannel: reservationChannel,
      directBillNumber: directBillNumber,
      directBillProjectNumber: directBillProjectNumber,
      directBillVCCExpiryDate: directBillVCCExpiryDate,
      dateRateMap: dateRateMap, //daily breakup of rate for more than two days of booking
      dateTaxAmountMap: dateTaxAmountMap,
      dateRateFeeMap: dateRateFeeMap
    };
  }
}

let ReservationApi = new ReservationFacade();
export {ReservationApi};
