import { exists } from '../base/dom-utils.js';
import CollectionOfHotels from '../pages/collection-hotels.js';

class FeaturedCard {
  constructor() {
    if (exists('.featurecard')) {
      this.handleDynamicCards();
      this.handleQuickView();
    }
  }

  initCarousel() {
    if($('.featured-card .featured-card-carousel').length > 0){
      $('.featured-card .featured-card-carousel').slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        rows: 0,
        responsive: [
          {
            breakpoint: 1199,
            settings: {
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 719,
            settings: {
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
          {
            breakpoint: 374,
            settings: {
              dots: true,
              infinite: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              adaptiveHeight: true,
            },
          },
        ],
      });
    }
  }

  handleDynamicCards() {
    let imgIdsArr = [];
    let requestObject = {};
    const featurecardInput = document.querySelector('.featured-card .dynamicFeaturedId');
    const collectionTypeInput = document.querySelector('.featured-card .collectionType');
    const dynamicIdsValue = featurecardInput.value;
    const collectionTypeValue = collectionTypeInput.value;
    const dynamicIdArr = dynamicIdsValue.split(',');
    if(collectionTypeInput &&  collectionTypeValue !== '') {
      requestObject.collectionType = collectionTypeValue;
    }
    if(featurecardInput && dynamicIdsValue !== '') {
      CollectionOfHotels.callCollectionAPI().then((res) => {
        const dynamicIdscollection = CollectionOfHotels.renderFcDynamicContent(dynamicIdArr, res);
          dynamicIdscollection.forEach((item)=> {
            imgIdsArr.push({brandId: item.brand.toLowerCase(), propertyId: item.id});
          });
          requestObject.properties = imgIdsArr; 
          CollectionOfHotels.getImageTaggingData(requestObject).then((res) => {
            const featuredCardContainer = document.querySelector('.featured-card-container');
            if(featuredCardContainer){
              dynamicIdscollection.forEach(cardItem => this.renderFeatureCard(cardItem, featuredCardContainer, res));
              this.initCarousel();
            }
          });
      });
    }
  }

  renderFeatureCard(cardItem, container, resData) {
    const cardListElement = document.createElement('div');
    cardListElement.classList.add('featured-card-list');
  
    const cardImageElement = document.createElement('div');
    cardImageElement.classList.add('featured-card-image');
  
    const carouselElement = document.createElement('div');
    carouselElement.classList.add('featured-card-carousel');
    cardImageElement.appendChild(carouselElement);
  
    const eyebrowElement = document.createElement('div');
    eyebrowElement.classList.add('featured-card-eyebrow');
    eyebrowElement.textContent = 'All Inclusive';
    cardImageElement.appendChild(eyebrowElement);
  
    const cardDetailsElement = document.createElement('div');
    cardDetailsElement.classList.add('featured-card-details');
  
    const headlineElement = document.createElement('h3');
    headlineElement.classList.add('headline-h');
    headlineElement.textContent = cardItem.name;
    cardDetailsElement.appendChild(headlineElement);
  
    const descriptionElement = document.createElement('div');
    descriptionElement.classList.add('featured-card-description');
  
    const locationElement = document.createElement('p');
    locationElement.classList.add('location-text');
    locationElement.textContent = `${cardItem.address.city}, ${cardItem.address.state}`;
    descriptionElement.appendChild(locationElement);
  
    const ratingElement = document.createElement('div');
    const taIframe = `<iframe width="140px" height="60px" scrolling="no" style="border: none; overflow: hidden;" src="https://www.tripadvisor.com/WidgetEmbed-cdsLicensedRating?locationId=${cardItem.id}&partnerId=699BC9B0A50349A389EF1084ECB2CA46&display=true"></iframe>`;
    ratingElement.classList.add('rating');
    ratingElement.innerHTML = taIframe;
    descriptionElement.appendChild(ratingElement);

    const quickViewBtnElement = document.createElement('a');
    quickViewBtnElement.classList.add('btn-secondary', 'quick-view-button');
    quickViewBtnElement.href = DOMPurify.sanitize(cardItem.uri);
    quickViewBtnElement.dataset.id = DOMPurify.sanitize(cardItem.id);
    quickViewBtnElement.textContent = 'Quick View';

    cardDetailsElement.appendChild(descriptionElement);
    cardDetailsElement.appendChild(quickViewBtnElement);
  
    cardListElement.appendChild(cardImageElement);
    cardListElement.appendChild(cardDetailsElement);
  
    const imageUrls = resData[cardItem.id] || [];
    imageUrls.forEach(imageUrl => {
      const pictureElement = document.createElement('picture');
  
      const sourceTablet = document.createElement('source');
      sourceTablet.media = '(min-width: 768px)';
      sourceTablet.srcset = imageUrl.tablet;
      pictureElement.appendChild(sourceTablet);
  
      const sourceMobile = document.createElement('source');
      sourceMobile.media = '(max-width: 767px)';
      sourceMobile.srcset = imageUrl.mobile;
      pictureElement.appendChild(sourceMobile);
  
      const imageItem = document.createElement('img');
      imageItem.alt = imageUrl.altText;
      imageItem.src = imageUrl.desktop;
      imageItem.classList.add('pll-image-full-size', 'lazy-load-pageload', 'carousel-img', 'featured-card-carousel-image');
      pictureElement.appendChild(imageItem);
  
      carouselElement.appendChild(pictureElement);
    });
  
    container.appendChild(cardListElement);
  }

  handleQuickView() {
    const featuredCardWrapper = document.querySelector('.featured-card');
    const quickViewLinks = featuredCardWrapper.querySelectorAll('.quick-view-button');

    if(quickViewLinks.length > 0){
      quickViewLinks.forEach((link) => {
          link.addEventListener('click', (e) => {
              e.preventDefault();
          });
      });
    }
  }
}

export default new FeaturedCard();
