/* eslint-disable camelcase */
import { _isNull, serializeObject, currencyRate } from '../base/utils.js';
import ConfigsUtils from '../base/aem-configs/config-utils.js';
import { _LOCALE_,
  $_PAGE_,
  _defaultChannelId,
  overview_brandId,
  overview_propertyId,
  req_room_type,
  reservationDataFromAEM
} from '../base/vars.js';
import {
  getElTrueHeight,
  scrollToAnimated
} from '../base/dom-utils.js';
import Analytics from '../base/bnpl-uplift-analytics.js';

class BNPLUplift {
  constructor() {
    this.$0 = $('.page-content,footer');
    this.initialized = false;
    this.$hotelname = null;
    this.$cancel = null;
    this.$save = null;
    this.$price = 0;
    this.$currency = null;
    this.$config = null;
    this.$locale = window._SEO_LOCALE_;
    this.$upliftApiUrl = null;
    this.$checkout = false;
    this.$checkinDate = '';
    this.$checkoutDate = '';
    this.$propertyName = '';
    this.$noOfRooms = 1;
    this.$upliftLocales = '';
    this.$rateCode = '';
    this.$initializPayment = false;
    this.bookingconfirm;
    this.checkoutConfirm;
    this.guranteeCode;
  }

  /**
   * Tracks the BNPL (Buy Now Pay Later) brand status
   * @param {string} currencyCode - The currency code to be used for checking the configuration.
   */
  trackBNPLBrandStatus(currencyCode) {
    if (this.handleUpliftCurrencyConfig(currencyCode) && !this.handleUpliftBrandDisabled()) {
      window.digitalData.buyNowPayLater.brandStatus = 'on';
      Analytics.satelliteTracker('buyNowPayLaterStatusSet');
    }
  }

  handleConfig(paymentRequest){

    window.digitalData.buyNowPayLater.propertyStatus = 'on';

    let config = this.handleUpliftCurrencyConfig(paymentRequest.currencyCode);

    const brandDisabled = this.handleUpliftBrandDisabled();
    const isRatePlanDisabled = paymentRequest && paymentRequest.room && paymentRequest.room.ratePlanId && this.isRatePlanDisabled(paymentRequest.room.ratePlanId);

    //this.$upliftLocales
    this.checkoutConfirm = paymentRequest && paymentRequest.checkoutConfirm;
    /*if (paymentRequest && paymentRequest.checkoutConfirm) {
      console.log('got it');
      
    } else {
      console.log('dont got it');
      this.checkoutConfirm = 'hi';
    }*/

    if(this.handleCurrencyConverter() && config && !brandDisabled && (!isRatePlanDisabled || !paymentRequest.checkout)){

      window.digitalData.buyNowPayLater.brandStatus = 'on';

      //console.log('paymentRequest',config,brandDisabled,this.$upliftLocales);
      this.$price = paymentRequest.subtotal || this.$price;
      this.$checkinDate = paymentRequest.checkinDate;
      this.$checkoutDate = paymentRequest.checkoutDate;
      this.$checkout = paymentRequest.checkout;
      this.$propertyName = paymentRequest.propertyName || this.$propertyName;
      this.$noOfRooms = paymentRequest.noOfRooms || this.$noOfRooms;

      try {
        this.$config = config && JSON.parse(config);
      } catch(err) {
        return;
      }

      if(this.$config.Locales != null) {
        try {
          this.$upliftLocales = this.$config.Locales.reduce((result, item) => {
            return (item.toLowerCase() === this.$locale && item) ? item : this.$config.Locales[0];
          }, null);
        } catch(err) {
          this.$upliftLocales = null;
        }
      }

      this.$upliftApiUrl = this.handleUpliftURL();
      this.$currency = paymentRequest.currencyCode;
 
      if(this.$checkout){
        this.guranteeCode = paymentRequest.room && paymentRequest.room.guranteeCode;
        this.bookingconfirm = paymentRequest.bookingconfirm;
        this.$rateCode = paymentRequest && paymentRequest.room && paymentRequest.room.ratePlanId;
        if(!$('.wyn-uplift-content .wyn-uplift-currency-code').text().includes(paymentRequest.currencyCode)){
          $('.wyn-uplift-content .wyn-uplift-currency-code').prepend(paymentRequest.currencyCode);
        }
        $('.wyn-uplift-content.booking .wyn-uplift-price').attr('data-up-price-value', paymentRequest.subtotal * 100);
        $('.wyn-uplift-content .wyn-uplift-price').attr('data-up-price-value', paymentRequest.subtotal * 100);
        if (paymentRequest.currencyCode === 'USD') {
          $('.wyn-uplift-content .wyn-uplift-currency-symbol').show();
          $('.wyn-uplift-content .wyn-uplift-currency-value').removeClass('notUSD');
          $('.wyn-uplift-content .wyn-uplift-currency-symbol').text('$');
        } else {
          $('.wyn-uplift-content .wyn-uplift-currency-symbol').hide();
          $('.wyn-uplift-content .wyn-uplift-currency-value').addClass('notUSD');
        }
        $('.wyn-uplift-content .wyn-uplift-promo').attr({
          'data-up-details-hotel_reservations-0-property_code': overview_propertyId,
          'data-up-details-hotel_reservations-0-room_type': req_room_type,
          'data-up-details-hotel_reservations-0-check_in': this.$checkinDate,
          'data-up-details-hotel_reservations-0-rate_code': this.$rateCode,
          'data-up-details-hotel_reservations-0-reservation_type': paymentRequest && paymentRequest.room && this.isPrepaidCodes(paymentRequest.room.guranteeCode)
        });
      }
      this.init();

      // Triggers buyNowPayLaterStatusSet if the digital data has changed
      Analytics.buyNowPayLaterStatusSetListener();

    } else {
      Analytics.satelliteTracker('buyNowPayLaterStatusSet');
    }
  }

  handleCurrencyConverter(){
    return ((!currencyRate || !currencyRate.currency_code) || this.handleUpliftCurrencyConfig(currencyRate.currency_code) || ($_PAGE_.is('.booking-page') && !$_PAGE_.is('.packages-page')));
  }

  handleUpliftURL(){
    return ConfigsUtils.getConfigs('bnpl-api-url',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any).reduce((result , item) => {
      return (item.name === 'Url' && item.value) ? item.value : result;
    },null);
  }

  handleUpliftCurrencyConfig(currency){
    return ConfigsUtils.getConfigs('supported-bnpl-currencies-keys',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any).reduce((result , item) => {
      return (item.name === currency && item.value) ? item.value : result;
    },null);
  }

  /*
  handleUpliftLocaleConfig(currency){
    return ConfigsUtils.getConfigs('supported-bnpl-locales',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any).reduce((result , item) => {
      return (item.name === "Locales" && item.value) ? item.value : result;
    },null);
  }
  */

  handleUpliftRatePlanDisabled(){
    return ConfigsUtils.getConfigs('rate-plans-to-exclude-bnpl',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any).reduce((result , item) => {
      return (item.name === 'RatePlanId' && item.value) ? item.value : result;
    },null);
  }

  handleUpliftPrepaidCodes(){
    return ConfigsUtils.getConfigs('bnpl-prepaid-rate-guaranteed-code',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any).reduce((result , item) => {
      return (item.name === 'PrepaidCodes' && item.value) ? item.value : result;
    },null);
  }

  handleUpliftDepositCodes() {
    return ConfigsUtils.getConfigs('bnpl-guaranteed-deposit-codes', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce((result, item) => {
      return (item.name === 'DepositCode' && item.value) ? item.value : result;
    }, null);
  }

  isRatePlanDisabled(ratePlanId){
    //console.log(this.handleUpliftRatePlanDisabled(),ratePlanId);
    try {
      return this.handleUpliftRatePlanDisabled() && JSON.parse(this.handleUpliftRatePlanDisabled()).includes(ratePlanId);
    } catch(err) {
      return false;
    }

  }

  isPrepaidCodes(ratePlanId){
    //console.log(this.handleUpliftRatePlanDisabled(),ratePlanId);
    try {
      return (this.handleUpliftPrepaidCodes() && JSON.parse(this.handleUpliftPrepaidCodes()).includes(ratePlanId)) ? 'pre_paid' : 'standard';
    } catch(err) {
      return 'standard';
    }

  }

  isDepositCodes(ratePlanId) {
    //console.log(this.handleUpliftRatePlanDisabled(),ratePlanId);
    try {
      return (this.handleUpliftDepositCodes() && JSON.parse(this.handleUpliftDepositCodes()).includes(ratePlanId)) ? true : false;
    } catch (err) {
      return 'false';
    }

  }

  handleUpliftBrandDisabled(){
    // import implementation from the configuration utils
    return ConfigsUtils.getUpliftBrandDisabled();
  }

  buildOrderInfo() {
    let traveler = {};
    let billing_contact = {};

    if (this.$checkout) {
      for (const [key, value] of Object.entries(serializeObject($('#booking-req-user-form')))) {
        window.digitalData.buyNowPayLater.APITracker = 'formLoad';
        if(value){
          switch (key) {
          case 'customerFirstName':
            traveler['first_name'] = value;
            billing_contact['first_name'] = value;
            break;
          case 'customerLastName':
            traveler['last_name'] = value;
            billing_contact['last_name'] = value;
            break;
          case 'customerPostalCode':
            billing_contact['postal_code'] = value;
            break;
          case 'customerCity':
            billing_contact['city'] = value;
            break;
          case 'customerCountryCode':
            billing_contact['country'] = value;
            break;
          case 'customerStateCode':
            billing_contact['region'] = (value.includes('US_') || value.includes('CA_')) ? value.split('_')[1] : '';
            break;
          case 'customerAddress':
            billing_contact['street_address'] = value;
            break;
          case 'customerPhone':
            billing_contact['phone'] = value;
            break;
          case 'customerEmail':
            billing_contact['email'] = value;
            break;
          default:
            break;
          }
        }
      }
    }
    return {
      travelers: [
        // Traveler information
        {...traveler},
      ],
      billing_contact: billing_contact,
      add_ons:[
        // Add-ons information
      ],
      hotel_reservations: [
        {
          'hotel_name': this.$propertyName,
          'number_of_rooms': this.$noOfRooms,
          'room_type': req_room_type,
          'check_in': this.$checkinDate,
          'check_out': this.$checkoutDate,
          'brand': overview_brandId, // Brand id with two letter
          'property_code': overview_propertyId, // property id with five number
          'reservation_type': this.isPrepaidCodes(this.guranteeCode),
          'has_deposit': this.isDepositCodes(this.guranteeCode),
          'rate_code': this.$rateCode,  //Waiting for response what to be update here
          'insurance': [
            // Insurance Details
          ],
        },
      ],
      merchant: window.brand,   // Need to Update from Variable constatnt
      merchant_field_1 : "Wyndham Hotels & Resorts", //overview_brandId, // Brand id with two letter
      merchant_field_2 : window.brand,
      order_amount: Math.round(this.$price * 100), // xxxxx equivalent to  $xxx.xx
    };
  }

  initPayMonthly() {
    if (this.$config && this.$config.API_Key && window.Uplift && window.Uplift.Payments) {
      window.Uplift.Payments.init({
        apiKey: this.$config.API_Key,
        locale: this.$upliftLocales,
        currency: this.$currency,
        checkout: this.$checkout,
        channel: 'desktop',
        container: '#up-pay-monthly-container',
        onChange: this.myOnChangeCallback
      });
    }
  }

  init() {
    if (this.$upliftApiUrl && this.$config && !_isNull(this.$upliftApiUrl) && !_isNull(this.$config)) {
      $('#upliftBillPayment').show();
      $('.payment-method-select-option').show();
      $('#direct-bill-body-payment').hide();
      $('.direct-bill-label').hide();
      $('.my-wallet-title').hide();
      this.initUplift();
    }
  }

  myOnChangeCallback(response) {
    window.digitalData.buyNowPayLater.serviceStatus = 'on';

    /**
     * Handles the response from the Uplift API
     * Adds the reason codes to digitalData object for Adobe launch rules mappings
     *
     * @param {Object} response - The response object from the Uplift API.
     */
    const handleUpliftReasons = (response) => {
      if (response.reasons && response.reasons.length > 0) {
        window.digitalData.buyNowPayLater.responseReasonCode = response.reasons.join(',');
      } else {
        window.digitalData.buyNowPayLater.responseReasonCode = null;
      }
    };

    var statusHandlers = {
      OFFER_AVAILABLE: function(){
        // STATUS: Uplift Pay Monthly Offer is available for this orderInfo
        // 1. show payment selectors
        // 2. show monthly pricing node in the selector
        // 3. hide "NOT AVAILABLE" node in the selector
        // 4. enable Pay Monthly selector
        // 5. disable Purchase/Book button

        if ($_PAGE_.is('.booking-page') && !$_PAGE_.is('.packages-page')) {
          document.getElementById("up-offer-not-available").style = 'display: none';
          document.getElementById("up-pay-monthly-selector-from-pricing").style = 'display: block';
          document.getElementById('upliftBillPayment').classList.remove('disabled');
          document.getElementById('uplift-monthly-payment').disabled = false;
          const paymentMethodRadio = document.querySelector('input[name="paymentmethodradio"]:checked');
          if (paymentMethodRadio && paymentMethodRadio.value == 'uplift-monthly-payment') {
            //Disabled button
            //$('button.confirm-booking').addClass('disabled-booking');
          }
          $(document).trigger('iframeStatusChange', [false]);
        }else if($_PAGE_.is('.packages-page')){
          $('#rate-summary-uplift-disclaimer').show();
          //$('#rate-summary-uplift-disclaimer').find('img').hide();
          //$('#rate-summary-uplift-disclaimer').children().eq(1).hide();
        }
        window.digitalData.buyNowPayLater.offerStatus = 'on';

        Analytics.handleBuyNowPayLaterStatus();
      },
      TOKEN_AVAILABLE: function(){
        // STATUS: Uplift application has been completed and Uplift is ready to pay
        // 1. show payment selectors
        // 2. enable Pay Monthly selector
        // 3. enable Purchase/Book button
        // The virtual card should be retrieved when user clicks the Purchase/Book button

        //$('button.confirm-booking').removeClass('disabled-booking');
        $(document).trigger('iframeStatusChange', [true]);

        window.digitalData.buyNowPayLater.APIStatus = 'upliftTokenAvailable';

        Analytics.satelliteTracker('upliftTokenAvailable');
      },
      TOKEN_RETRIEVED: function(){
        // STATUS: Token is available to be charged for payment
        // Uplift Payment Token successfully retrieved.
        // 1. utilize the retrieved token (virtual card) to pay in full by Uplift.
        var token = response.token;
        //console.log(token);
        if(token){
          if(token.card_token){
            $('#upToken').val(token.card_token);
            $('#isTokenBooking').val(true);

            window.digitalData.buyNowPayLater.APIStatus = 'upliftTokenRetrieved';

            Analytics.satelliteTracker('upliftTokenRetrieved');
            if(this.bookingconfirm){
              try {
                this.bookingconfirm();
              } catch(err) {
                console.log(err);
              }

            }
          } else {
            scrollToAnimated($('#up-pay-monthly-container').offset().top - getElTrueHeight($('.globalnavigation')) - 20);
            //console.log('uplift token is empty',response);
            $('.page').removeClass('loading');
          }
        }
        //process payment using token (uplift virtual card).
        //process payment using token (uplift virtual card).

        //process payment using token (uplift virtual card).


      },
      OFFER_UNAVAILABLE: function(){
        // STATUS:  Pay monthly offer is unavailable for this orderInfo
        // 1. show payment selectors
        // 2. show "NOT AVAILABLE" node in the selector
        // 3. hide monthly pricing node in the selector
        // 4. disable Pay Monthly selector
        // 5. change payment option selection, if Pay Monthly option is selected

        if ($_PAGE_.is('.booking-page') && !$_PAGE_.is('.packages-page')) {
          document.getElementById('up-offer-not-available').style = 'display: block';
          document.getElementById('up-pay-monthly-selector-from-pricing').style = 'display: none';
          document.getElementById('upliftBillPayment').classList.add('disabled');
          document.getElementById('uplift-monthly-payment').disabled = true;
          //$('button.confirm-booking').addClass('disabled-booking');
          $(document).trigger('iframeStatusChange', [false]);
        }else if($_PAGE_.is('.packages-page')){
          $('#rate-summary-uplift-disclaimer').show();
          //$('#rate-summary-uplift-disclaimer').find('img').hide();
          //$('#rate-summary-uplift-disclaimer').children().eq(1).hide();
        }

        window.digitalData.buyNowPayLater.offerStatus = 'off';

        Analytics.handleBuyNowPayLaterStatus();
      },
      SERVICE_UNAVAILABLE: function(){
        // STATUS:  Uplift API is unavailable
        // 1. do not show payment selectors

        if ($_PAGE_.is('.booking-page')) {
          $('#upliftBillPayment').hide();
        }
        window.digitalData.buyNowPayLater.serviceStatus = 'off';

        Analytics.handleBuyNowPayLaterStatus();
      },
      SERVICE_AVAILABLE: function(){
        // STATUS:  Uplift API is available

        if ($_PAGE_.is('.booking-page') && !$_PAGE_.is('.packages-page')) {
          document.getElementById('up-offer-not-available').style = 'display: none';
          document.getElementById('up-pay-monthly-selector-from-pricing').style = 'display: block';
          document.getElementById('upliftBillPayment').classList.remove('disabled');
          document.getElementById('uplift-monthly-payment').disabled = false;
        }else if($_PAGE_.is('.packages-page')){
          $('#rate-summary-uplift-disclaimer').show();
          //$('#rate-summary-uplift-disclaimer').find('img').hide();
          //$('#rate-summary-uplift-disclaimer').children().eq(1).hide();
        }

        window.digitalData.buyNowPayLater.serviceStatus = 'on';
        Analytics.satelliteTracker('buyNowPayLaterStatusSet');
        window.digitalData.buyNowPayLater.buyNowPayLaterStatusFired = 'fired';
      }
    };

    // add uplift reasons to digital data
    handleUpliftReasons(response);

    statusHandlers[response.status]();
    // Reset digitalData form interaction tracker
    window.digitalData.buyNowPayLater.APITracker = '';
  }

  initUplift() {
    if(this.initialized && window.Uplift && window.Uplift.Payments){                        //Initialize Uplift.Payments
      var orderInfo = this.buildOrderInfo();
      if(!this.$initializPayment){        //Build orderInfo Object
        this.initPayMonthly();
        this.$initializPayment = true;
      }
      window.Uplift.Payments.load(orderInfo);
    } else {
      (function(u, p, l, i, f, t, o, b, j) {
        u['UpLiftPlatformObject'] = f;
        u[f] = u[f] || function() {
          (u[f].q = u[f].q || []).push(arguments);
        }, u[f].l = 1 * new Date();
        b = p.createElement(l), j = p.getElementsByTagName(l)[0];
        b.async = 1;
        b.src = i + '?id=' + t;
        j.parentNode.insertBefore(b, j);
        o = u.location.host.match(/[\w-]+\.\w{2,3}(:\d+)?$/);
        if (o) o = o[0];
        u[f]('create', t, o);
      })(window, document, 'script', this.$upliftApiUrl, 'up', this.$config.UP_Code);
      this.initialized = true;
    }
  }

  instantiate($parent) {
    this.$0 = $parent;
    this.initialized = false;
  }
}

const BNPL = new BNPLUplift();

window.upReady = () => {
  var orderInfo = BNPL.buildOrderInfo();
  if(!BNPL.$initializPayment){
    BNPL.initPayMonthly(); //Initialize Uplift.Payments
    BNPL.$initializPayment = true;
  }

  if(BNPL.checkoutConfirm === 'BNPL'){
    window.Uplift.Analytics.orderResponse({
      //user’s mode of payment
      "mode_of_payment": 'pay monthly',
      //pass the confirmation number
      "order_id": reservationDataFromAEM.confirmationNumbers
    });
    window.Uplift.Payments.confirm(reservationDataFromAEM.confirmationNumbers);
    window.digitalData.confirmation.confirmInfo.reservationConfirmationNumber = reservationDataFromAEM.confirmationNumbers;
  } else {
    window.Uplift.Payments.load(orderInfo);
  }
};

export default BNPL;

