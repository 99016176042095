import {$_PAGE_,} from './vars.js';

/**
 * Uplift Buy now pay later Analytics is a class responsible for tracking Buy Now Pay Later (BNPL)
 * related events and interactions on a page.
 *
 */
class BnplUpliftAnalytics {

  constructor() { }

  /**
   * Check if the current page is BNPL tracked page.
   *
   * @returns {boolean} - Returns true if the current page should be tracked, otherwise return false .
   */
  isBnplTrackedPage() {

    const pagesToBeTracked = [
      'rooms-rates-page',
      'confirm',
      'booking-page',
      'modify-page',
      'confirmation-page',
      'property-page'
    ];

    // Check if the current page is in the list or has the 'reservation-modified' class
    return pagesToBeTracked.some(pageClass => $_PAGE_.hasClass(pageClass)) || $('.page-content').hasClass('reservation-modified');
  }

  /**
   * Track Uplift Buy Now Pay Later (BNPL) status on page load.
   */
  initializeBNPLDigitalDataOnPageLoad() {
    window.digitalData.buyNowPayLater.brandStatus = 'off';
    window.digitalData.buyNowPayLater.propertyStatus = 'off';
    window.digitalData.buyNowPayLater.serviceStatus = 'off';
    window.digitalData.buyNowPayLater.offerStatus = 'off';
    window.digitalData.buyNowPayLater.tokenStatus = 'off';
    window.digitalData.buyNowPayLater.buyNowPayLaterStatusFired = 'notFired';
    window.digitalData.buyNowPayLater.formInitialized = false;
  }

  /**
   * Adds a listener to monitor changes in the buyNowPayLaterStatusFired property and
   * triggers an event when it changes.
   */
  buyNowPayLaterStatusSetListener() {
    // Create a custom event to be dispatched when the property changes
    const statusFiredChangedEvent = new CustomEvent('statusFiredChanged');

    // Add an event listener for the custom event
    window.addEventListener('statusFiredChanged', () => {
      if (window.digitalData.buyNowPayLater.buyNowPayLaterStatusFired !== 'fired') {
        console.log('Logs fired from buyNowPayLaterStatus');
        this.satelliteTracker('buyNowPayLaterStatusSet');
      }
    });

    window.digitalData.buyNowPayLater = new Proxy(window.digitalData.buyNowPayLater, {
      set: (target, property, value) => {
        if (property === 'buyNowPayLaterStatusFired') {
          target[property] = value;
          window.dispatchEvent(statusFiredChangedEvent);
          return true;
        }
        target[property] = value;
        return true;
      },
    });
  }

  /**
   * Uplift Disclaimer Phone Click Tracking Functionality
   * This is responsible for tracking clicks on the Uplift Disclaimer Phone Number
   * It binds a click event listener to the phone number span and updates the digitalData object
   * It also triggers the Adobe Launch event "upliftDisclaimerPhoneClick"
   */
  trackUpliftDisclaimerPhoneClick()   {
    const phoneNumberSpan = document.querySelector('.uplift-disclaimer-on-confirmation .phone-number');

    if (phoneNumberSpan) {
      phoneNumberSpan.addEventListener('click', this.handlePhoneNumberClick.bind(this));
    }
  }

  /**
   * Handles the click event on phone number span, updates the digitalData object,
   * and triggers the satelliteTracker for the "upliftDisclaimerPhoneClick" event.
   * @param {Event} event - The click event.
   */
  handlePhoneNumberClick(event) {
    const phoneNumber = event.currentTarget.innerText;

    // Update digitalData object with the phone number clicked
    window.digitalData.buyNowPayLater.upliftDisclaimerConfirmationPhoneClick = phoneNumber;

    // Trigger Adobe Launch/Analytics event "upliftDisclaimerPhoneClick"
    this.satelliteTracker("upliftDisclaimerPhoneClick");
  }

  /**
   * This function handles the tracking of the Buy Now Pay Later status.
   * It performs the following tasks:
   * 1. If the form is not initialized, it triggers the 'buyNowPayLaterStatusSet' event
   *    to track the Buy Now Pay Later status.
   * 2. Sets the 'buyNowPayLaterStatusFired' property to 'fired' on the window.digitalData object.
   * 3. If the APITracker property is set to 'formLoad', it updates the 'formInitialized' property to true.
   */
  handleBuyNowPayLaterStatus() {
    if (window.digitalData.buyNowPayLater.formInitialized === false) {
      // Trigger the 'buyNowPayLaterStatusSet' event for tracking
      this.satelliteTracker('buyNowPayLaterStatusSet');
    }

    window.digitalData.buyNowPayLater.buyNowPayLaterStatusFired = 'fired';

    if (window.digitalData.buyNowPayLater.APITracker === 'formLoad') {
      window.digitalData.buyNowPayLater.formInitialized = true;
    }
  }


  /**
   * Triggers a direct call event in Adobe Launch if the _satellite object is available and the directCall parameter is defined.
   *
   * @param {string} directCall - The event name to be tracked in Adobe Launch.
   */
  satelliteTracker(directCall) {
    if (this.isBnplTrackedPage() && !$_PAGE_.hasClass('packages-page')) {
      if (typeof window._satellite !== 'undefined' && typeof directCall !== 'undefined') {
        window._satellite.track(directCall);
      }
    }
  }

}

/**
 * Class to handle the tracking of uplift rate click events.
 */
class UpliftTracker {

  /**
   * Constructor to initialize the MutationObserver.
   */
  constructor() {
    this.initObserver();
  }

  /**
   * Initializes a MutationObserver to watch for changes in the DOM
   * and rebinds the uplift click event listener to uplift price new divs added to the DOM.
   * This was mainly added to accommodate the property page where the rates are generated on scroll
   */
  initObserver() {
    const observer = new MutationObserver((mutations) => {
      if(mutations.length > 0){
        this.trackUpliftRateClick();
      }
    });

    const targetNode = document.body;
    const config = { childList: true, subtree: true };
    observer.observe(targetNode, config);
  }

  /**
   * Adds a click event listener to the uplift price element.
   *
   * @param {HTMLElement} upliftPrice - The uplift price element.
   */
  addUpliftPriceClickListener(upliftPrice) {
    upliftPrice.addEventListener('click', this.handleUpliftPriceClick.bind(this));
  }

  /**
   * Removes the click event listener from the uplift price element.
   *
   * @param {HTMLElement} upliftPrice - The uplift price element.
   */
  removeUpliftPriceClickListener(upliftPrice){
    upliftPrice.removeEventListener('click', this.handleUpliftPriceClick.bind(this));
  }

  /**
   * Attaches a click event listener to each uplift price element
   * and tracks the uplift rate click event.
   */
  trackUpliftRateClick() {
    const upliftPrices = document.querySelectorAll('.wyn-uplift-price');

    upliftPrices.forEach((upliftPrice) => {
      this.removeUpliftPriceClickListener(upliftPrice);
      this.addUpliftPriceClickListener(upliftPrice);
    });
  }

  /**
   * Checks if the promo element is not visible.
   * @param {HTMLElement} upliftPrice - The uplift price element.
   * @returns {boolean} - Returns true if the promo element does not exist or if it is not visible (display: none), otherwise false.
   */
  isPromoNotVisible(upliftPrice) {
    const promoElement = upliftPrice.querySelector('.wyn-uplift-promo');

    // If promoElement does not exist or its display property is set to 'none', return true
    return !promoElement || window.getComputedStyle(promoElement).display === 'none';
  }

  /**
   * Gets the rate text for booking and property pages without spaces.
   * @param {HTMLElement} upliftPrice - The uplift price element.
   * @returns {string} - The rate text without spaces.
   */
  getRateTextForBookingAndPropertyPages(upliftPrice) {
    const currencySymbolElement = upliftPrice.querySelector('.wyn-uplift-currency-symbol');
    const currencyValueElement = upliftPrice.querySelector('[data-up-from-currency-unit-major]');
    const currencyCodeElement = upliftPrice.querySelector('.wyn-uplift-cta:not([data-up-from-currency-unit-major]):not(.wyn-uplift-currency-symbol):not([data-up-tooltip])');

    if (!currencySymbolElement || !currencyValueElement || !currencyCodeElement) return;

    const currencySymbol = currencySymbolElement.textContent;
    const currencyValue = currencyValueElement.textContent;
    let currencyCode = currencyCodeElement.textContent;

    // Remove any three-letter currency code, ignoring the case
    currencyCode = currencyCode.replace(/[A-Z]{3}/gi, '');

    // Remove any extra white spaces
    return `${currencySymbol}${currencyValue}${currencyCode}`.replace(/\s/g, '');
  }

  /**
   * Function to get the rate text for other pages other than booking and property pages.
   *
   * @param {HTMLElement} upliftPrice - The HTML element containing the uplift price information.
   * @returns {string} - The formatted rate text for analytics reporting
   */
  getRateTextForOtherPages(upliftPrice) {
    // Check if the passed upliftPrice is null or not an instance of HTMLElement
    if (!upliftPrice || !(upliftPrice instanceof HTMLElement)) return '';

    // Select the currency symbol element
    const currencySymbolElement = upliftPrice.querySelector('.wyn-uplift-currency-symbol.wyn-uplift-cta');

    // Select the currency value element
    const currencyValueElement = upliftPrice.querySelector('[data-up-from-currency-unit-major]');

    // Select the currency code element
    const currencyCodeElement = upliftPrice.querySelector('.wyn-uplift-currencyCode.wyn-uplift-cta');

    // Check if all the necessary elements are found and if they contain the method 'textContent'
    if (!currencySymbolElement || !currencyValueElement || !currencyCodeElement ||
      !('textContent' in currencySymbolElement) ||
      !('textContent' in currencyValueElement) ||
      !('textContent' in currencyCodeElement)) return '';

    // Check if the parent node of currency code element is not null and contains the property 'textContent'
    if (!currencyCodeElement.parentNode || !('textContent' in currencyCodeElement.parentNode)) return '';

    // Extract the text content (including "/mo") from the parent span of currency code element
    const perMonthElement = currencyCodeElement.parentNode.textContent;

    // Extract the text content from each of the selected elements
    const currencySymbol = currencySymbolElement.textContent;
    const currencyValue = currencyValueElement.textContent;
    const currencyCode = currencyCodeElement.textContent.trim();

    // Construct the rate text using the extracted text content and remove the extra spaces
    return `${currencySymbol}${currencyValue.trim()}${perMonthElement.replace(currencyCode, '').trim()}`;
  }

  /**
   * Handles the click event on uplift price elements,
   * extracts relevant data, updates the digitalData object,
   * and triggers the satelliteTracker for the uplift Rate Price Click event.
   * @param {Event} event - The click event.
   */
  handleUpliftPriceClick(event) {
    if (event.alreadyHandled) return; // Check if the event has already been handled
    event.alreadyHandled = true; // Mark the event as handled

    const upliftPrice = event.currentTarget;
    const dataUpId = upliftPrice.dataset.upId;
    const promoNotVisible = this.isPromoNotVisible(upliftPrice);

    let rateText;

    // Check if the current page has 'booking-page' and 'property-page' class
    if ($_PAGE_.hasClass('booking-page') || $_PAGE_.hasClass('property-page')) {
      rateText = this.getRateTextForBookingAndPropertyPages(upliftPrice);
    } else {
      rateText = this.getRateTextForOtherPages(upliftPrice);
    }

    const rateClick = {
      upId: dataUpId,
      text: rateText
    };

    window.digitalData.buyNowPayLater.rateClick = rateClick;

    if (promoNotVisible) {
      bnplAnalyticsInst.satelliteTracker('interestBearing');
    } else {
      bnplAnalyticsInst.satelliteTracker('interestFree');
    }
  }

}

const upliftTracker = new UpliftTracker();

let bnplAnalyticsInst = new BnplUpliftAnalytics();

// init digital data
bnplAnalyticsInst.initializeBNPLDigitalDataOnPageLoad();

// globalize uplift rate click for all bnpl pages
upliftTracker.trackUpliftRateClick();

export default bnplAnalyticsInst ;
