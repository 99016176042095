class CardsCarousel {
  constructor() {
    this.instancesColumn3 = $('.cards-carousel.column-3');
    this.instancesColumn2 = $('.cards-carousel.column-2');

    if (this.instancesColumn3.length > 0) {
      this.initCarousel3();
      window.at.wait(this.instancesColumn3, (root) => this.initCarousel3(root));
    }
    if (this.instancesColumn2.length > 0) {
      this.initCarousel2();
      window.at.wait(this.instancesColumn2, (root) => this.initCarousel2(root));
    }
  }
  initCarousel3(root) {
    $.each($('.cards-carousel.column-3 .cards-container', root), (i, e) => {
      $(e).on('init', (event, slick) => {
        this.equalizeCardHeights(event.currentTarget);
        this.showHideDots(slick);
      });
      $(e).slick({
        dots: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        rows: 0,
        responsive: [{
          breakpoint: 1199,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            arrows: false
          }
        },
        {
          breakpoint: 719,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            adaptiveHeight: true
          }
        }
        ]
      });
      $(e).on('setPosition', (event, slick) => {
        this.equalizeCardHeights(event.currentTarget);
      });
    });
  }
  initCarousel2(root) {
    $.each($('.cards-carousel.column-2 .cards-container', root), (i, e) => {
      $(e).on('init', (event, slick) => {
        this.showHideDots(slick);
        this.equalizeCardHeights(event.currentTarget);
      });
      $(e).slick({
        dots: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        rows: 0,
        responsive: [{
          breakpoint: 1199,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 2,
            arrows: false
          }
        },
        {
          breakpoint: 719,
          settings: {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            adaptiveHeight: true
          }
        }
        ]
      });
      $(e).on('breakpoint', (event, slick, breakpoint) => {
        this.showHideDots(slick);
      });
      $(e).on('setPosition', (event, slick) => {
        this.equalizeCardHeights(event.currentTarget);
      });
    });
  }
  showHideDots(slick) {
    let breakpoint = slick.activeBreakpoint || Math.max.apply(null, slick.breakpoints);
    if (slick.$dots && breakpoint) {
      let slidesToShow = slick.breakpointSettings[breakpoint].slidesToShow || 1;
      if (slick.$slides.length > slidesToShow) {
        slick.$dots.show();
      } else {
        slick.$dots.hide();
      }
    }
  }
  equalizeCardHeights(carousel) {
    $(carousel).find('.slick-slide')
      .height('auto');

    let slickTrack = $(carousel).find('.slick-track'),
      slickTrackHeight = $(slickTrack).height();

    $(carousel).find('.slick-slide')
      .css('height', slickTrackHeight + 'px');
  }
}

export default new CardsCarousel();
