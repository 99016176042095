import {callService, EventHandler, getServiceUrl, _isValidBrand, _isValidBrandTier} from "../base/utils";
import {getBrand, getCriteria, getSearchOverview, setCriteria} from "../base/session-handler";
import {$_PAGE_, _LOCALE_, overview_propertyId} from "../base/vars";
import FeaturedAmenityIcons from "./featured-amenity-icons.js";
import PropertyPolicies from "./property-policies.js";
import PropertyAmenities from "./property-amenities.js";
import Handlebars from 'handlebars';
import {exists} from "../base/dom-utils";

class PropertyCard {

    constructor() {
        this._data = {};
        if ($_PAGE_.is('.rooms-rates-page') && $('#bb-qview-l').length) {
            EventHandler.one(EventHandler.BookingBarLoad, () => {
                this._getPropertyOverview();
                this._getAmenityIcons();
            });
        }
    }

    // this will set this._data.propertyOverview
    _getPropertyOverview() {

        let searchOverview = getSearchOverview();
        if(!searchOverview || searchOverview.propertyId !== overview_propertyId) {
            if(overview_propertyId) {
                setCriteria({ propertyId: overview_propertyId });
            }
            let params = $.extend(true, {}, getCriteria());
            let p = {
                isOverviewNeeded: true,
                propertyId: params.propertyId,
                isAmenitiesNeeded: true,
                channelId: 'tab',
                language: _LOCALE_
            };
            callService('overview', p).then((res) => {
                if (res && res.status === 'OK') {
                    this._data.propertyOverview = res.properties[0];
                    this._getPropertyImages();
                    this._tryRender();
                }
            });
        } else {
            this._data.propertyOverview = searchOverview;
            this._getPropertyImages();
            this._tryRender();
        }
    }
    // this will set this._data.propertyImages
    _getPropertyImages() {

        let pr = { items: `${_LOCALE_}:${this._data.propertyOverview.propertyId}:${getBrand(false)}`, ignoreOverrideTags: true };

        callService(getServiceUrl('getPropertyImage'), pr).then((res) => {
            if (res && res.length) {
                let searchResponse = res[0][0].searchResponse;
                if (searchResponse) {
                    this._data.propertyImages = searchResponse;
                    this._data.templateType = searchResponse.templateType;
                    this._tryRender();
                }
            }
        });
    }
    // this will set this._data.amenitiesIconMap
    _getAmenityIcons() {

        FeaturedAmenityIcons.getAmenityIconsMap().then(
          res => {
            this._data.amenitiesIconMap = res;
            this._tryRender();
          }
        );
    }

    // this will check the following have been fetched
    // * this._data.propertyOverview
    // * this._data.propertyImages
    // * this._data.amenitiesIconMap
    _tryRender() {

        if(this._data.propertyOverview && this._data.propertyImages && this._data.amenitiesIconMap) {

            const model = {
                carousel: new PropertyImages(this._data.propertyOverview, this._data.propertyImages),
                policies: new PropertyPolicies(this._data.propertyOverview),
                amenities: new PropertyAmenities(this._data.propertyOverview, this._data.amenitiesIconMap),
                propertyId: this._data.propertyOverview.propertyId,
                locale: _LOCALE_
            };

            this._renderHb('#bb-qview-l', model);
            $('.carousel-inner .item:first').addClass("active");

            this._renderHb('#bb-qview', model);
            $('#bb-qview .bb-qview-carousel .carousel-inner .item:first').addClass("active");
            //initialize mobile gallery
            if (exists('#uuCarousel')) {
                $('#bb-qview-l .carousel, #bb-qview .carousel').carousel({
                    swipe: true
                });

                //dots animation
                $('#uuCarousel').bind('slide.bs.carousel', function(e){
                    if (e.direction == "left") {
                        $('.carousel-dots-container').addClass("bb-next-dots");
                    }
                    if (e.direction == "right") {
                        $('.carousel-dots-container').addClass("bb-prev-dots");
                    }
                });

                $('#uuCarousel').bind('slid.bs.carousel', function(e){
                    if(e.direction == "left"){
                        $('.carousel-dots-container').removeClass("bb-next-dots");
                    }
                    if(e.direction == "right"){
                        $('.carousel-dots-container').removeClass("bb-prev-dots");
                    }
                });
            }
            //create the link for View All Amenities
            if (exists('.property-card')) {
                let amenitiesUrl;
                if (this._data.templateType.includes('upscale')) {
                    amenitiesUrl = window.location.href.replace('rooms-rates', 'services-amenities');
                } else {
                    amenitiesUrl = window.location.href.replace('rooms-rates', 'overview');
                }
                $('#bb-view-amenities, #bb-view-amenities-mobile').attr('href', amenitiesUrl + '#amenities-section-title');
            }
        }
    }
    _renderHb(viewSelector, model) {

        let view = $(viewSelector);
        if (view.length > 0) {
			let template = Handlebars.compile(view.html());
			view.html(template(model));
			view.css('visibility', 'visible');
        }
    }
}

/**
 * TODO: move this class out into a separate js file when it is determined that another component will reuse it.
 * Checkout property-amenities.js usage against property-card.js history 4/20/22 1:29 PM
 */
class PropertyImages {
    constructor(propertyOverview, propertyImages) {
        let groups = propertyImages.imageList;

        let newRequestURL = `${window.location.protocol}//${window.location.host}/bin/propertyDataList.json`;
        const brand = this._getBrandCode(window.overview_brandId, window.overview_brandTier);

        let hotelRequestData = {
            locale: _LOCALE_,
            hotels: []
        },
        hotelObject = {
            brandId: brand,
            hotelId: window.overview_propertyId,
            location: window.property_state_code
        };
        hotelRequestData.hotels.push(hotelObject);

        $.ajax({
            url: newRequestURL,
            type: 'POST',
            data: JSON.stringify(hotelRequestData),
            dataType: 'json',
            contentType: 'application/json',
            success: (result, status, xhr) => {
                if (result && result.hotels.length > 0 && result.error === false) {
                    const image = $('.bb-qview-bgimg img');
                    const imageObj = result.hotels[0].image;
                    image.attr('data-mobile-src', imageObj.mobile);
                    image.attr('data-tablet-src', imageObj.tablet);
                    image.attr('data-src', imageObj.desktop);
                    image.attr('alt', imageObj.altText);
                    image.attr('src', imageObj.mobile);
                    image.addClass('lazy-load');
                }
            },
            error: (result, status, error) => {
                const image = $('.bb-qview-bgimg img');
                let thumbnail = '';
                if (groups.length > 0 && groups[0].imageList.length > 0) {
                    thumbnail = groups[0].imageList[0].replace(/downsize=\d+/, 'downsize=720');
                    let objImageCategory = groups.filter((obj) => {
                        return obj.imageCategory === 'Exterior';
                    });
                    if (objImageCategory.length > 0) {
                        thumbnail = objImageCategory[0].imageList[0].replace(/downsize=\d+/, 'downsize=720');
                    }
                }
                image.attr('src', thumbnail);
                EventHandler.triggerEvent('brands-error', result);
            }
        });

        // create a list of category labels with total count per category
        let slideTo = 0;
        let total = 0;
        this.aggregatedGroupLabels = [];
        for(let group of groups) {
            total += group.imageList.length;
            this.aggregatedGroupLabels
                .push({
                    label: `${group.imageCategory} (${group.imageList.length})`,
                    slideTo: slideTo
                });
            slideTo += group.imageList.length;
        }
        // augment the original list
        let slideIndex = 1;
        this.images = [];
        for(let group of groups) {
            let category = group.imageCategory;
            for(let url of group.imageList) {
                this.images.push({
                    id: Math.random().toString(36).slice(-8),
                    alt: `${category} ${slideIndex++} / ${total}, ${propertyOverview.name}`,
                    src: url,
                    tablet_src: url.replace(/downsize=\d+/, 'downsize=1200'),
                    mobile_src: url.replace(/downsize=\d+/, 'downsize=720')
                });
            }
        }
    }

    _getBrandCode(brand, tier) {
        if(_isValidBrand(brand)) {
            return brand;
        } else if(_isValidBrandTier(tier)) {
            return tier;
        }
        return getBrand(false);
    }
}

export default new PropertyCard();
