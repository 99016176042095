import {
  BBForm,
  initBBSubPieces
} from '../components/booking-bar.js';
import BookingSummary from '../components/booking-summary.js';
import {
  exists,
  scrollToAnimated
} from '../base/dom-utils.js';
import Dropdown from '../components/dropdown.js';
import RateDetails from '../components/rate-details.js';
import {
  _isNotNull,
  _isNumber,
  _isWeakerTrue,
  formatDateForBWS,
  formatDateForPrinting,
  formatNumber,
  getCurrencyMapping,
  replaceToken,
  getDateFromDateString,
  getHeaderHeight,
  getNumDays,
  handlePostSubtotal,
  CookieHandler,
  callService,
  EventHandler
} from '../base/utils.js';
import {
  $_PAGE_,
  _LOCALE_,
  changeUrl,
  selectUrl,
  confirmUrl,
  property_city_name,
  property_country_code,
  property_country_name,
  property_state_code,
  property_state_name,
  reservationDataFromAEM
} from '../base/vars.js';
import Analytics from '../base/wyn-analytics-module.js';
import {
  getDefaultCriteria,
  getLocationDetails,
  normalizeSearchCriteria,
  setLocationDetails,
  getBrand,
  getBrandTier,
  ReservationHandler,
  deletePropAvailSessionData
} from '../base/session-handler.js';
import { currencyConverter } from '../base/currency-converter.js';

function scrollToModifyTop() {
  scrollToAnimated($('.booking-status-component').offset().top - (getHeaderHeight() + $('#bookingBarHeight').outerHeight()));
}

class ModifyBookingStatus {
  constructor() {
    if ($_PAGE_.is('.modify-page')) {
      this.ignoreFirst = true;

      this.handleForward();
      this.handleBackward();
      deletePropAvailSessionData();
    }
  }

  handleForward() {
    //Forward through Modify Flow
    EventHandler.on('modify-view-changed-to-next', () => {
      if(!this.ignoreFirst) {
        $('.booking-status .progress-indicator .active:last')
          .removeClass('active')
          .addClass('complete')
          .next()
          .addClass('active');
      } else {
        this.ignoreFirst = false;
      }
    });
  }

  handleBackward() {
    //Backward through Modify Flow
    EventHandler.on('modify-view-changed-to-prev', () => {
      $('.booking-status .progress-indicator .active:last').removeClass('active'); //Remove .active
      $('.booking-status .progress-indicator .complete:last') //Revert last .complete li to .active
        .removeClass('complete')
        .addClass('active');
    });
  }
}
class ModifyBypassLink {
  constructor() {
    if($_PAGE_.is('.modify-page')){
      this.bindBypassLink();
    }
  }

  bindBypassLink() {
    $('.new-room-rate a').click((e) => {
      e.preventDefault();

      modifySessionInst.reset();
      EventHandler.send('modify:next');
    });
  }
}
class ModifyReservationHeader {
  constructor() {
    if(exists('#cancel-reservation-form')) {
      this.bindCancelLink();
    }
  }

  bindCancelLink() {
    $('.cancel-reservation-link').click((e) => {
      e.preventDefault();

      let data = modifySessionInst.original();

      data.taxList = JSON.stringify(data.taxList);

      // Even though we're coming from modify page, we want the view to be Reservation Found and not Reservation Modified
      ReservationHandler.postReservationDataToAEM(data, $('#cancel-reservation-form'), 'find');
    });
  }
}

class ModifySession {
  constructor() {
    this.originalData = $.extend(true, {}, reservationDataFromAEM);
    this.sessionData = null;
  }

  init() {
    let currencyRE = new RegExp(this.originalData.currencyCode, 'i');
    this.originalData.averageBeforeTax = this.originalData.subtotal.replace(currencyRE, '').replace(/^\D/, '')
      .trim() * 1;
    this.originalData.averageAfterTax = this.originalData.totalCash.replace(currencyRE, '').replace(/^\D/, '')
      .trim() * 1;
    this.originalData.totalBeforeTax = this.originalData.subtotal.replace(currencyRE, '').replace(/^\D/, '')
      .trim() * 1;
    this.originalData.totalAfterTax = this.originalData.totalCash.replace(currencyRE, '').replace(/^\D/, '')
      .trim() * 1;
    if (this.originalData.taxList.match(/^[\{\[]/)) {
      this.originalData.taxList = JSON.parse(this.originalData.taxList);
      reservationDataFromAEM.taxList = JSON.parse(reservationDataFromAEM.taxList);
    }
   

    EventHandler.on('modify:reset', () => {
      this.reset();
    }).on('modify:set', (e, data) => {
      this.set(data);
    });
  }
  reset() {
    this.sessionData = $.extend(true, {}, reservationDataFromAEM);

    // Remove unneeded values and sensitive data
    delete this.sessionData.roomDescriptionLong;
    delete this.sessionData.roomDescriptionShort;
    delete this.sessionData.taxList;
    delete this.sessionData.customerState;
    delete this.sessionData.customerZip;
    delete this.sessionData.email;
    delete this.sessionData.firstName;
    delete this.sessionData.lastName;

    this.sessionData.brandId = getBrand(true);
    this.sessionData.brandTier = getBrandTier();

    BBForm.setFrom(this.sessionData.checkInDate);
    BBForm.setTo(this.sessionData.checkOutDate);

    normalizeSearchCriteria(this.sessionData);

    setLocationDetails({
      city: property_city_name || 'Orlando',
      state: property_state_name || 'FL',
      stateCode: property_state_code || 'FL',
      countryCode: property_country_code || 'US',
      country: property_country_name || 'US'
    });
    BBForm.setDestination(getLocationDetails().output.cityStateAbbr);
  }
  original() {
    return $.extend(true, {}, this.originalData);
  }
  get() {
    return $.extend(true, {}, this.sessionData);
  }
  set(obj) {
    $.extend(true, this.sessionData, obj);
  }
}

class ModifyViewController {
  constructor() {
    this.currentView = 0;
    this.viewUrls = [changeUrl, selectUrl, confirmUrl];

    this.$oldResponseContainer = null;
    this.newResponseClass = `.response-container-${this.currentView}`;
    this.$newResponseContainer = null;

    EventHandler.on('modify:next', () => this.next(() => {
      scrollToModifyTop();
    }));
  }

  getCurrent() {
    return this.currentView;
  }

  setCurrent(num) {
    this.currentView = num;
  }

  getOldResponseClass() {
    return `.response-container-${(this.currentView - 1)}`;
  }

  getNewResponseClass() {
    return `.response-container-${this.currentView}`;
  }

  next(cb) {
    ++this.currentView;

    this.$oldResponseContainer = $(this.getOldResponseClass()); //update response containers
    this.$newResponseContainer = $(this.getNewResponseClass());

    if (exists(this.$newResponseContainer)) {
      this.$oldResponseContainer.fadeOut({
        complete: () => {
          this.$newResponseContainer.fadeIn();
        }
      });

      this.handlePageClass();

      if (typeof cb === 'function') {
        cb();
      }

      let newView = this.$newResponseContainer.find('.fragment-container').data('view');
      EventHandler.send('modify-view-changed');
      EventHandler.send('modify-view-changed-to-' + newView);
      EventHandler.send('modify-view-changed-to-next');
    } else {
      this.getFragment(cb);
    }
  }

  prev(cb) {
    this.$newResponseContainer = $(this.getNewResponseClass());
    this.$oldResponseContainer = $(this.getOldResponseClass());

    this.$newResponseContainer.fadeOut({
      complete: () => {
        this.$oldResponseContainer.fadeIn();
      }
    });

    --this.currentView;

    this.handlePageClass();

    if (typeof cb === 'function') {
      cb();
    }

    let newView = this.$oldResponseContainer.find('.fragment-container').data('view');
    EventHandler.send('modify-view-changed');
    EventHandler.send('modify-view-changed-to-' + newView);
    EventHandler.send('modify-view-changed-to-prev');
  }

  handlePageClass() {
    let newView = $(this.getNewResponseClass()).find('.fragment-container')
        .data('view'),
      pageView = $_PAGE_.data('view');
    $_PAGE_.removeClass(pageView).addClass(newView)
      .data('view', newView);
  }

  getFragment(cb) {
    EventHandler.send('modify-view-requesting-fragment');

    $.ajax({
      url: this.viewUrls[(this.currentView - 1)],
      data: {
        isFullyTranslated: window.is_fully_translated
      },
      success: (r) => {
        this.placeFragmentOnPage(r, cb);
      },
      error: (res) => {
        EventHandler.triggerEvent('brands-error', res);
      }
    });
  }

  placeFragmentOnPage(r, cb) {
    let todayDate = new Date();
    todayDate.setHours(0,0,0,0);
    this.$newResponseContainer = $(document.createElement('div'));

    if (exists(this.$oldResponseContainer)) {
      this.$oldResponseContainer.hide();
    }

    this.$newResponseContainer
      .addClass('response-container response-container-' + this.currentView)
      .html(r)
      .hide();
    $('.page-view-container').append(this.$newResponseContainer);
  
    if(!(reservationDataFromAEM.packages && reservationDataFromAEM.packages.length) && reservationDataFromAEM && reservationDataFromAEM.directBillNumber && reservationDataFromAEM.directBillNumber.length > 0 &&
       getDateFromDateString(todayDate) <= getDateFromDateString(reservationDataFromAEM.directBillVCCExpiryDate)) {
      $('.direct-bill-info-message').show();
      if( $('.disclaimer-edit-dates').length > 0 )
        $('.disclaimer-edit-dates').css('margin-bottom','0px');
      let text = $('.db-message').text();
      text = replaceToken(text, '${today}', formatDateForBWS(new Date())) || text;
      text = replaceToken(text, '${checkoutdate}', formatDateForBWS(reservationDataFromAEM.directBillVCCExpiryDate)) || text;
      $('.db-message').text(text);
    }
    if (this.$newResponseContainer[0].querySelector('.fragment-container').getAttribute('data-view') == 'confirm') {
      currencyConverter.updateDisclaimerTime();
    }
    
    if((reservationDataFromAEM.packages && reservationDataFromAEM.packages.length)){
      $('.response-container-' + this.currentView).hide();
      $('.progress-indicator').hide();
      $('.modify-default-heading').hide();
      $('.modify-package-heading').show();
      $('.modify-default-notes').hide();
      $('.modify-package-notes').show();
      $('.front-desk-details').hide();
      $('.modify-package-front-desk-details').html($('.modify-package-front-desk-details').text().replace('{supportPhone}',`<a calss="property-number" href="tel${reservationDataFromAEM.propertyPhone}" >${reservationDataFromAEM.propertyPhone}</a>`));
      $('.modify-package-front-desk-details').show();
    }else{
      $('.progress-indicator').show();
      this.$newResponseContainer.fadeIn();
    }

    this.handlePageClass();

    if (typeof cb === 'function') {
      cb();
    }

    EventHandler.send('modify-view-changed');
    EventHandler.send('modify-view-changed-to-' + this.$newResponseContainer.find('.fragment-container').data('view'));
    EventHandler.send('modify-view-changed-to-next');
  }
}
class Modify {
  constructor() {
    this._working = false;
    this._workingOnModifyReservation = false;
    if ($_PAGE_.is('.modify-page')) {
      $(() => {
        this.viewController = new ModifyViewController();
        this.modifySession = modifySessionInst;
        this.modifyViewConfirmed();
        this.modifyViewLoaded();

        EventHandler.one('modify-view-changed-to-change', () => {
          initBBSubPieces();
          BBForm.init(false);

          Dropdown.init();

          // Rooms and guests dropdown should be visible on mobile for modify
          $('.page-view-container .mini-booking .rooms-and-guests-dropdown').removeClass('hidden-xs');
          $('.page-view-container .mini-booking .rooms-and-guests-button-container').hide();
          if ($_PAGE_.is('.modify-page')) {
            BBForm.setRooms(this.modifySession.get().rooms);
          }

          Analytics.setModifiedReservationOriginalData(this.modifySession.original());
        });

        this.init();
      });

    }
  }

  init() {
    EventHandler.one(EventHandler.criteria.init, () => {
      this.viewController.next(() => {
        new ModifyBypassLink();
        new ModifyReservationHeader();
        new ModifyBookingStatus();
        scrollToModifyTop();
      });

      BookingSummary.handleCheckIn(BBForm.getFrom());
      BookingSummary.handleCheckOut(BBForm.getTo());
    });

    this.modifySession.init();
  }
  modifyViewConfirmed() {
    EventHandler.on('modify-view-changed-to-confirm', () => {
      $('.complete-reservation a').click((e) => {
        e.preventDefault();
        if ($('.modify-page .modify-error').length > 0) {
          $('.modify-disclaimer-div-container .modify-disclaimer-review-changes').find('.modify-error').remove();
          if ($('.direct-bill-info-message').length > 0) {
            $('.disclaimer-header-container-3').css({'margin-bottom' : '40px'});
          }
        }
        if (!(this._workingOnModifyReservation)) {
          this._workingOnModifyReservation = true;
          let overview_orsId = _isNotNull(window.orsId) ? window.orsId : '';
          let data = this.modifySession.get(),
            original = this.modifySession.original(),
            dataForCall = {
              hotelId: (data.brandId === 'WY') ? overview_orsId : data.propertyId,
              propertyID: data.brandId + data.propertyId,
              hotelBrand: data.brandId,
              brandTier: data.brandTier,
              srcBrand: data.brandId,
              confirmation: data.confirmationNumbers || data.confirmationNumber,
              customerFirstName: original.firstName,
              noOfRooms: data.rooms || original.rooms,
              noOfAdults: data.adults || original.adults,
              noOfChildren: data.children || original.children,
              childAge: data.childAge,
              checkInDate: formatDateForBWS(data.checkInDate || original.checkInDate),
              checkOutDate: formatDateForBWS(data.checkOutDate || original.checkOutDate),
              totalBeforeTax: data.totalBeforeTax,
              totalAfterTax: data.totalAfterTax,
              ratePlanCode: data.ratePlanId,
              roomTypeCode: data.roomTypeCode,
              customerEmail: original.email,
              customerLastName: original.lastName,
              customerCity: original.customerCity,
              customerAddress: original.customerAddress,
              customerPhone: original.customerPhone,
              customerStateCode: original.customerState,
              customerCountryCode: original.customerCountry,
              customerPostalCode: original.customerZip,
              deviceType: CookieHandler.getDeviceTypeFromCookie(),
              channelId: getDefaultCriteria().channelId,
              reservationChannel: data.reservationChannel,
              language: _LOCALE_,
              dbNo: data.directBillNumber || '',
              dbPrj: data.directBillProjectNumber || ''
            };

          callService('modifyReservation', dataForCall, 'POST').then((response) => {
            if (response && response.status === 'OK') {
              let checkInDate = getDateFromDateString(data.checkInDate),
                checkOutDate = getDateFromDateString(data.checkOutDate),
                nights = getNumDays(checkInDate, checkOutDate),
                giftCardPrice = data.giftCard,
                taxTotal = parseFloat(data.totalAfterTax - data.totalBeforeTax).toFixed(2),
                currency = data.currencyCode,
                taxList = (_isNotNull(data.taxInfo)) ? data.taxInfo : JSON.stringify(data.taxList),
                dateRateMap = (_isNotNull(response.modify.rates.RoomRate.Rates.Rate.Base.dateRateMap)) ? (JSON.stringify(response.modify.rates.RoomRate.Rates.Rate.Base.dateRateMap)) : '',
                dateTaxAmountMap = (_isNotNull(response.modify.rates.RoomRate.Rates.Rate.Base.dateTaxAmountMap)) ? (JSON.stringify(response.modify.rates.RoomRate.Rates.Rate.Base.dateTaxAmountMap)) : '',
                dateRateFeeMap = (_isNotNull(response.modify.rates.RoomRate.Rates.Rate.Base.dateRateFeeMap)) ? (JSON.stringify(response.modify.rates.RoomRate.Rates.Rate.Base.dateRateFeeMap)) : '',
                

                obj = {
                  confirmationNumber: data.confirmationNumber,
                  confirmationNumbers: data.confirmationNumbers,
                  firstName: original.firstName,
                  lastName: original.lastName,
                  roomDescriptionLong: data.description,
                  rooms: data.rooms,
                  nights: nights,
                  adults: data.adults,
                  children: data.children,
                  checkInDateFormatted: formatDateForPrinting(checkInDate, 'weekdayCompact'),
                  checkInDate: data.checkInDate,
                  checkInTime: data.checkInTime,
                  checkOutDateFormatted: formatDateForPrinting(checkOutDate, 'weekdayCompact'),
                  checkOutDate: data.checkOutDate,
                  checkOutTime: data.checkOutTime,
                  roomDescriptionShort: data.shortName,
                  subtotal: handlePostSubtotal(data.totalBeforeTax, giftCardPrice, currency, data.ratePlanId),
                  taxTotal: getCurrencyMapping(currency, true) + taxTotal + ' ' + currency,
                  taxList: taxList,
                  totalCash: data.fnsRatePlan ? '' : getCurrencyMapping(currency,true) + data.totalAfterTax.toFixed(2) + ' ' + currency,
                  totalPoints: formatNumber(data.pacRatePlan ? data.totalPacPoints : data.fnsRatePlan ? data.totalFnspoints : ''),
                  cancellationPolicy: data.cancelPolicy,
                  depositPolicy: data.depositPolicy,
                  giftCard: _isNumber(giftCardPrice) ? getCurrencyMapping(currency, true) + giftCardPrice.toFixed(2) + ' ' + currency : '',
                  propertyId: data.propertyId,
                  roomType: data.roomTypeCode,
                  brandId: data.brandId,
                  brandTier: data.brandTier,
                  currencyCode: currency,
                  rateCode: data.ratePlanId,
                  roomCode: data.roomCode,
                  cancellable: _isWeakerTrue(data.cancellable) ? 'Y' : 'N',
                  modifiable: _isWeakerTrue(data.modifiable) ? 'Y' : 'N',
                  cancelled: _isWeakerTrue(data.cancellation) ? 'Y' : '',
                  email: original.email,
                  comments: data.comments,
                  customerAddress: original.customerAddress,
                  customerPhoneNum: original.customerPhone,
                  customerCity: original.customerCity,
                  customerState: original.customerState,
                  customerCountry: original.customerCountry,
                  customerZip: original.customerZip,
                  propertyName: original.propertyName,
                  propertyAddress: original.propertyAddress,
                  propertyPhone: original.propertyPhone,
                  ratePlanName : data.ratePlanName,
                  directBillNumber:data.directBillNumber || '',
                  directBillProjectNumber:data.directBillProjectNumber || '',
                  directBillVCCExpiryDate:data.directBillVCCExpiryDate || '',
                  dateRateMap: dateRateMap, //daily breakup of rate for more than two days of booking
                  dateTaxAmountMap: dateTaxAmountMap, //daily breakup of rate for more than two days of booking
                  dateRateFeeMap: dateRateFeeMap, //daily breakup of rate for more than two days of booking
                };
              Analytics.updateCurrencyCode(currency);
              ReservationHandler.postReservationDataToAEM(obj, $('#cancel-reservation-form'), 'modify');
            } else {
              console.log(response);
              let errResponse = (typeof response == "object") ? response.responseJSON : '';
              if (errResponse && errResponse.status == 'Error' && errResponse.errmsg && errResponse.errmsg.length > 0) {
                let errmsg = response.responseJSON.errmsg || '';
                let $parent = $('.modify-page.confirm .modify-disclaimer-div-container .modify-disclaimer-review-changes');
                let $divEle = $(document.createElement('div')).addClass('modify-error').text(errmsg);
                $parent.append($divEle);
                if ($('.direct-bill-info-message').length > 0) {
                  $('.disclaimer-header-container-3').css({'margin-bottom' : '10px'});
                }
              } else {
                console.error('Modify service error');
              }
            }
            this._workingOnModifyReservation = false;
          });
        }
      });
      Analytics.reviewModifyRoomsRates(this.modifySession.original(), this.modifySession.get());
    });
  }
  modifyViewLoaded() {
    $('body').click((e) => {
      let $0 = $(e.target);

      if ($0.is('.modify-flow-back-button')) {
        this.viewController.prev(() => {
          scrollToModifyTop();
        });
      }
    });

    if (this.modifySession.original().dateRateMap) {
      RateDetails.updateRateBreakdown(JSON.parse(this.modifySession.original().dateRateMap), this.modifySession.original().currencyCode);
    }

    $('#rateSummaryDetail').on('show.bs.modal', (e) => {
      let data;

      if (exists($(e.relatedTarget).parents('.new-rate-summary'))) {
        data = this.modifySession.get();
        RateDetails.handleAll(data);
        /*Though handleAll will in turn call updateRateBreakdown but it will take data from the Modify.original().
        For populating the data in the modal for 'new-rate-summary' we need the dateRateMap of Modify.get() */
        RateDetails.updateRateBreakdown(this.modifySession.get().dateRateMap, this.modifySession.get().currencyCode, true);
      } else if (exists($(e.relatedTarget).parents('.current-rate-summary'))) {
        data = this.modifySession.original();
        RateDetails.handleAll(data);
      } else {
        return;
      }
    });

    $(window).load(() => {
      // Change to Select View
      EventHandler.on('modify-view-changed-to-select', () => {
        Analytics.getSelectNewRoomRate();
      });
    });
  }
}

let modifySessionInst = new ModifySession();

export {
  modifySessionInst as ModifySession
};

export default new Modify();
