import { EventHandler } from '../../base/utils.js';
import ExtendedHeroVideoSlide from './extended-hero-video-slide.js';
import {lazyLoadImage} from '../../base/lazy-load.js';
import { isMobileWidth, WatchHandler } from '../../base/dom-utils.js';

class ExtendedHeroContainer {
  constructor() {
    if ($('.extended-hero-container').length > 0) {
      $('.extended-hero-container').find('.extended-hero-carousel')
        .each((i, el) => {
          $(el).find('.ghost').remove();
          if (!$(el).is(':visible')) {
            this.watchForCarouselDisplay($(el));
          } else {
            this.initHeroCarousel($(el));
          }
        });
    }
  }
  watchForCarouselDisplay($el) {
    let visCheck = new WatchHandler();
    visCheck.init($el, 'hero:visible', {
      fn: 'is',
      params: ':visible'
    });
    EventHandler.on('hero:visible', () => {
      visCheck.stop();

      this.initHeroCarousel($el);
    });
  }
  initHeroCarousel($el) {
    let componentContainer = $el.parent(),
      arrowContainer = componentContainer.find('.hero-arrow-controls'),
      autoScroll = false,
      scrollSpeed = 0;

    if ($el.data('interval')) {
      autoScroll = true;
      scrollSpeed = ($el.data('interval') * 1000);
    }

    $el.on('init', (event, slick) => {
      let images = $(event.currentTarget).find('.extended-hero-image-slide'),
        videos = $(event.currentTarget).find('.extended-hero-video-slide');

      if (images) {
        images.each((i, e) => {
          lazyLoadImage($(e));
        });
      }
      if (videos && isMobileWidth()) {
        videos.each((i, e) => {
          lazyLoadImage($(e));
        });
      }

      if (videos && typeof (jwplayer) != 'undefined') {
        ExtendedHeroVideoSlide.initVideos(videos);
        ExtendedHeroVideoSlide.onSlideChange();
      }

      this.showHideDots(slick);
      if (slick.$slides.length > 0) {
        this.switchCarouselStyleClasses(event.currentTarget, slick.$slides[slick.currentSlide].classList);
      }
    });

    $el.slick({
      appendArrows: $(arrowContainer),
      autoplay: autoScroll,
      autoplaySpeed: scrollSpeed,
      speed: 800,
      rows: 0,
      responsive: [{
        breakpoint: 1199,
        settings: {
          arrows: false
        }
      }]
    });

    $el.on('breakpoint', (event, slick, breakpoint) => {
      this.showHideDots(slick);

      $(event.currentTarget).find('.has-bg-img')
        .each((i, e) => {
          lazyLoadImage($(e));
        });
    });

    $el.on('beforeChange', (event, slick, currentSlide, nextSlide) => {
      if (slick.$slides.length > 0) {
        this.switchCarouselStyleClasses(event.currentTarget, slick.$slides[nextSlide].classList);
      }
    });
    arrowContainer.find('.slick-arrow').on('click', (e) => {
      $el.slick('slickPause');
    });
  }
  showHideDots(slick) {
    let breakpoint = slick.activeBreakpoint || Math.max.apply(null, slick.breakpoints);
    if (slick.$dots && breakpoint) {
      let slidesToShow = slick.breakpointSettings[breakpoint].slidesToShow || 1;
      if (slick.$slides.length > slidesToShow) {
        slick.$dots.show();
      } else {
        slick.$dots.hide();
      }
    }
  }
  switchCarouselStyleClasses(carousel, classList) {
    let classes = classList;

    $(carousel).parent()
      .removeClass((i, css) => {
        return (css.match(/(\bindicators-\S+|\btext-box-overlay-\S+)/g) || []).join(' ');
      });

    $.each(classes, (i, e) => {
      if ((e.indexOf('indicators-') == 0) || (e.indexOf('text-box-overlay-') == 0)) {
        $(carousel).parent()
          .addClass(e);
      }
    });
  }
}

export default new ExtendedHeroContainer();
