import { CookieHandler, callService } from '../base/utils.js';
import {
  exists,
  isDesktopWidth
} from '../base/dom-utils.js';
import {
  setCriteria
} from '../base/session-handler.js';
import {
  _LOCALE_,
  unloadEvent
} from '../base/vars.js';

class LanguageSelector {
  constructor() {
    if (exists('.language-selector')) {
      this.handleUserPreferredLanguageCookie();

      this.bindLoadLanguageSelector();
      this.bindCloseLanguageSelector();
    }
  }

  handleUserPreferredLanguageCookie() {
    // Set user language cookie on page load for non en-us locales
    if (_LOCALE_ && _LOCALE_ !== 'en-us') {
      CookieHandler.createCookie('userPreferredLanguage', _LOCALE_, 365);
    }
  }

  bindLoadLanguageSelector() {
    // Load only when user interacts with the dropdown
    $('#languageDropdown').click((e) => {
      if (!$(e.currentTarget).data('loaded')) {
        // Load HREF elements for different locales
        callService('/bin/languagepaths.json', {
          path: window.pageProperties.pagePath,
          pathname: window.location.pathname.replace("/" + window._SEO_LOCALE_ ,"")
        }).then((res) => {
        
          if (res && res.length > 0) {
            this.bindIndividualLanguageClicks(res);
            $(e.currentTarget).data('loaded', true);
          }

        });
      }
    });
  }

  bindIndividualLanguageClicks(res) {
    $.each(res, (index, obj) => {
      $('.language-selector li[data-locale=' + obj.locale + '] a').attr('href', obj.url.replace('/packages','') + window.location.search)
        .click((e) => {
          e.preventDefault();
          e.stopPropagation();

          this.updateUserLanguagePreference(obj.locale, () => {
            $(window).off(unloadEvent, setCriteria);
            window.location.assign($(e.currentTarget).attr('href'));
          });
        });
    });

  }
  updateUserLanguagePreference(locale, cb) {
    // Save user lang preference before redirecting
    CookieHandler.createCookie('userPreferredLanguage', locale, 365);

    setCriteria({
      inLanguageCity: '',
      inLanguageState: '',
      inLanguageCountry: '',
      city: '',
      country: '',
      countryCode: '',
      location: '',
      state: '',
      stateCode: '',
      latitude: '',
      longitude: '',
      categories: null
    });

    cb();
  }

  bindCloseLanguageSelector() {
    $(document).mouseup((e) => {
      if (isDesktopWidth() && $('.language-selector .dropdown').hasClass('open') &&
        !$('.language-selector').is(e.target) && $('.language-selector').has(e.target).length === 0
      ) {
        $('#languageDropdown').click();
      }
    });
  }
}

export default new LanguageSelector();
