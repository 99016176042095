import Handlebars from 'handlebars';
import {callService, formatTimeShow, getServiceUrl, dateInRange, _isValidBrand, _isValidBrandTier} from "../base/utils";
import {getBrand} from "../base/session-handler";
import { $_PAGE_, _LOCALE_ } from "../base/vars";
import FeaturedAmenityIcons from "./featured-amenity-icons.js";
import PropertyPolicies from "./property-policies.js";
import PropertyAmenities from "./property-amenities.js";
import ExpandableItem from '../wr/components/expandable-item.js';
import UUMap from './upscale/uu-wide-map';
import {exists} from "../base/dom-utils";

class PropertyQuickView {
  constructor() {
    this._data = {};

    // Get Property Quick View modaal and template
    this._propertyQuickViewModal = $('#bb-md');
    this._propertyQuickViewTemplateElement = $('#propertyQuickViewTemplate');

    // Get Propery Policies modal and template
    this._propertyPoliciesModal = $('#hotelPoliciesLightbox');
    this._propertyPoliciesTemplateElement = $('#propertyPoliciesTemplate');

    if ($_PAGE_.is('.search-results-page') && this._propertyQuickViewModal.length && this._propertyQuickViewTemplateElement.length) {

      // Prepare Property Qucik View modal
      this._propertyQuickViewTemplate = Handlebars.compile(this._propertyQuickViewTemplateElement.html());
      this._propertyQuickViewContent = this._propertyQuickViewModal.find('.modal-content');

      // Prepare Property Policies modal
      this._propertyPoliciesTemplate = Handlebars.compile(this._propertyPoliciesTemplateElement.html());
      this._propertyPolicesContent = this._propertyPoliciesModal.find('.modal-content');

      ExpandableItem.bindEvents();
    }
  }

  init(propertyId, propertyUrl, bookNowUrl, notificationData) {
    this._propertyId = propertyId;
    this._propertyUrl = propertyUrl;
    this._bookNowUrl = bookNowUrl;

    if (notificationData) {
      notificationData.visible = notificationData.headlineText
          && this._dateInRange(notificationData.notificationStartDate, notificationData.notificationEndDate);
    }
    this._data.notificationData = notificationData;

    this._getPropertyData();
  }

  _getBrandCode(brand, tier) {
    if(_isValidBrand(brand)) {
        return brand;
    } else if(_isValidBrandTier(tier)) {
        return tier;
    }
    return getBrand(false);
  }

  _getPropertyData() {
    let params = {
      propertyId: this._propertyId,
      isOverviewNeeded: true,
      isAmenitiesNeeded: true,
      channelId: 'tab',
      language: _LOCALE_
    };

    callService('overview', params).then((res) => {
      if (res && res.status === 'OK') {
        this._data.propertyOverview = res.properties[0];
        const brandCode = this._getBrandCode(this._data.propertyOverview.brand, this._data.propertyOverview.brandTier);

        let hotelRequestData = {
            locale: _LOCALE_,
            hotels: []
        },
        hotelObject = {
            brandId: brandCode,
            hotelCode: this._propertyId,
            hotelId: this._propertyId,
            location: this._data.propertyOverview.country
        };
        hotelRequestData.hotels.push(hotelObject);
        
        $.ajax({
            url: window.location.protocol + '//' + window.location.host + '/bin/propertyDataList.json',
            type: 'POST',
            data: JSON.stringify(hotelRequestData),
            dataType: 'json',
            contentType: 'application/json',
            success: (result, status, xhr) => {
              if(result && result.hotels.length > 0 && result.error === false && result.hotels[0].phone){
                this._data.propertyOverview.phone = result.hotels[0].phone;
              }
            },
        }); 
        
        params = { items: `${_LOCALE_}:${this._propertyId}:${brandCode}` };

        callService(getServiceUrl('getPropertyImage'), params).then((res) => {
          if (res && res.length && res[0][0].searchResponse && res[0][0].searchResponse.imageList) {
            this._data.propertyOverview.propertyImages = [];

            for(let i = 0; i < res[0][0].searchResponse.imageList.length; i++) {
              this._data.propertyOverview.propertyImages.push(...res[0][0].searchResponse.imageList[i].imageList);
            }
          }

          FeaturedAmenityIcons.getAmenityIconsMap()
            .done((res) => {
              this._data.amenitiesIconMap = res;
              this._tryRender();
            })
            .fail(() => {
              this._tryRender();
            });
        });
      }
    });
  }

  _addViewPolicesListener() {
    $('#bb-view-policies').on('click', () => {
      this._propertyQuickViewModal.modal('hide');
      setTimeout(() => {
        this._propertyPoliciesModal.modal('show');
      }, 500);
    });
  }

  _dateInRange(start, end) {
    const startDate = start ? new Date(start) : '',
      endDate = end ? new Date(end) : '';

    if(startDate && endDate) {
      endDate.setMilliseconds(0);
      endDate.setSeconds(59);
      endDate.setMinutes(59);
      endDate.setHours(23);
      const todayDateUTC = new Date().toUTCString();
      const startDateUTC = startDate.toUTCString();
      const endDateUTC = endDate.toUTCString();
      return dateInRange(todayDateUTC, startDateUTC, endDateUTC);
    }
    return true;
  }

  _tryRender() {
    if (this._data.propertyOverview) {

      this._data.propertyOverview.propertyUrl = this._propertyUrl;
      this._data.propertyOverview.bookNowUrl = this._bookNowUrl;
      this._data.propertyOverview.checkInTime = formatTimeShow(this._data.propertyOverview.checkInTime);
      this._data.propertyOverview.checkOutTime = formatTimeShow(this._data.propertyOverview.checkOutTime);
      this._data.propertyOverview.notification = this._data.notificationData;
      this._data.propertyOverview.featuredAmenities = new PropertyAmenities(this._data.propertyOverview, this._data.amenitiesIconMap);
      this._propertyQuickViewContent.html(this._propertyQuickViewTemplate(this._data.propertyOverview));

      const propertyPolicies = new PropertyPolicies(this._data.propertyOverview);
      this._propertyPolicesContent.html(this._propertyPoliciesTemplate(propertyPolicies));

      // Initialize Google map
      const brandCode = this._getBrandCode(this._data.propertyOverview.brand, this._data.propertyOverview.brandTier);
      this._propertyQuickViewContent.find('#mapDiv').append(UUMap.getStaticMapImg(
        brandCode,
        this._data.propertyOverview.latitude,
        this._data.propertyOverview.longitude,
        12,
        '457x191',
        'bb-md-map-img'
      ));

      // Add View Policies listener
      this._addViewPolicesListener();

      // Show modal
      this._propertyQuickViewModal.modal('show');

      //initialize mobile gallery
      if (exists('#uuCarousel')) {
        $('#bb-md-carousel .carousel').carousel({
          swipe: true
        });

        //dots animation
        $('#uuCarousel').bind('slide.bs.carousel', function(e){
          if (e.direction == "left") {
            $('.carousel-dots-container').addClass("bb-next-dots");
          }
          if (e.direction == "right") {
            $('.carousel-dots-container').addClass("bb-prev-dots");
          }
        });

        $('#uuCarousel').bind('slid.bs.carousel', function(e){
          if(e.direction == "left"){
            $('.carousel-dots-container').removeClass("bb-next-dots");
          }
          if(e.direction == "right"){
            $('.carousel-dots-container').removeClass("bb-prev-dots");
          }
        });
      }
    }
  }
}

let propertyQuickViewInst = new PropertyQuickView();
window.Wyndham = window.Wyndham || {};
window.Wyndham.PropertyQuickView = propertyQuickViewInst;

export default propertyQuickViewInst;

