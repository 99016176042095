class TextOffer {
	constructor() {
		document.querySelectorAll('.card-button').forEach((el) => {
			el.addEventListener('click', function(ev) {
				var modalIndex = ev.target.href.indexOf("#modal");
				if (modalIndex > -1) {
					document.querySelector('#bookingBar__main').classList.remove('stick');
				}
			});
		});
	}
}

export default new TextOffer();
