import { exists } from '../base/dom-utils.js';
import WrNonParticipatingBrandConfig from '../base/aem-configs/wr-non-participating-brands-config.js';

class GlobalNavigation {
  constructor() {
    if (exists('.navbar')) {
      this.hideWRWhenNotParticipating();
    }
  }

  hideWRWhenNotParticipating() {
    if (!WrNonParticipatingBrandConfig.isCurrentBrandParticipatingToWR()) {
      $('.navbar__wyndham-rewards-container').remove();
      if ($('.language-selector').length) {
        $('.navbar__main-links').addClass('wr-non-participation')
          .removeClass('col-md-15')
          .addClass('col-md-20');
      }
    } else if(['av','lt'].includes(window.brand_id)){
      $('.navbar__wyndham-rewards-container').remove();
      if ($('.language-selector').length) {
        $('.navbar__main-links').addClass('wr-non-participation')
          .removeClass('col-md-15')
          .addClass('col-md-20');
      }
    }
  }
}

export default new GlobalNavigation();
