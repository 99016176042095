import {
  $_PAGE_,
  _LOCALE_,
  _defaultChannelId,
  overview_brandId,
  overview_orsId,
  overview_propertyId,
  property_country_code,
  req_rate_plan,
  req_room_type,
  req_rate_cc,
  req_roomImg,
  reservationDataFromAEM
} from '../base/vars.js';
import BNPLUplift from '../components/bnpl-uplift.js';
import BookingSummary from '../components/booking-summary.js';
// We need to ignore eslint for next line because this constructor should be called in booking page
import BookingForm from './booking-form.js'; // eslint-disable-line no-unused-vars
import ConfigsUtils from '../base/aem-configs/config-utils.js';
import {
  isDesktopWidth,
  isTabletWidth,
  isMobileWidth,
} from '../base/dom-utils.js';
import {
  getCriteria,
  setCriteria,
  getSessionCriteria,
  setSessionStorage,
  getSessionStorage,
  setLocalStorage,
  getLocalStorage,
  goToBook,
  goToPackages,
  getSearchOverview,
  ReservationHandler
} from '../base/session-handler.js';
import {
  formatDateForBWSAvailability,
  getDateFromDateString,
  formatDateForUplift,
  callService,
  CookieHandler,
  getNumDays,
  convertToCurrency,
  getSelectedCurencyCode,
  getCurrencyMapping,
  formatDateForPrinting,
  printDollarAmount,
  getName,
  isGoFreeRate,
  isSMORate,
  isBNPLEX,
  EventHandler,
  _isNotNull,
} from '../base/utils.js';
import RateSummaryCurrent from '../components/rate-summary.js';
import rateDetails from '../components/rate-details.js';


class PackagesAddons {
  constructor() {
    this._params = {};
    this._data = {};
    this._data.packages = getLocalStorage('addon-packages') || {};
    this.totalRates = 0;
    this.criteria = getSessionCriteria();
    this.rateCounter = 0;
    this.isGroupCodeExist = false;
    this.addonsCategories = ConfigsUtils.getConfigs('add-ons-categories',_defaultChannelId,[_LOCALE_],ConfigsUtils.Any);
    this._emeaRegion = false;
    this.lastEventToUndo = {};
    this.toatalAmountAftertaxwithPackage = 0;
    this.tostMessage = $('#packageQuantityUpdate > .toast-message');
    this.undoForRemovedPackage = $('#packageQuantityUpdate').find('.toast-undo');
    this.initPackages();
    this.handlePackagesOverview();

    this.handlePackageQuantityUpdateToast();
    this.triggerBooking();
  }

  initPackages() {
    this.initParams();
    this.populateCriteria();
    this.handleMultiRoomAlert();
    if($_PAGE_.is('.packages-page')){
      this.initBNPLUplift();
    }


  }

  onGetProfileSignOut() {
    if ($_PAGE_.is('.booking-page')) {
      EventHandler.one(EventHandler.fullProfile.fetched + ' ' + EventHandler.profile.signedOut, () => {
        this.fetchPackages();
      });
      if($_PAGE_.is('.packages-page')){
        EventHandler.one('bookdata.complete', () => {
           EventHandler.one('getPackagesEvent', () => {
             this.populatePackageOnBooking();
           });
        });
        EventHandler.one(EventHandler.profile.signedIn, () => {
          this.fetchPackages();
        });
      }else {
         EventHandler.one('bookdata.complete', () => {
          this.populatePackageOnBooking();
         });
      }
    }
  }


  populateCriteria() {
    let sp = this._params,
      checkInDate = getDateFromDateString(sp.checkInDate),
      checkOutDate = getDateFromDateString(sp.checkOutDate);
    setCriteria({
      nights: getNumDays(checkInDate, checkOutDate)
    });

    BookingSummary.handleAdults(sp.adults);
    BookingSummary.handleChildren(sp.children);
    BookingSummary.handleRooms(sp.rooms);
    let pfpCookie = CookieHandler.readCookie('pfpCookie');
    if (!(sp.iata == null || sp.iata == '') && (pfpCookie != sp.iata) && (window.digitalData && window.digitalData.search && window.digitalData.search.searchInfo && window.digitalData.search.searchInfo.iataCode)) {
      $('input[name="iata"]').val(window.digitalData.search.searchInfo.iataCode);
    } else if (pfpCookie) {
      $('input[name="iata"]').val(pfpCookie).prop('disabled', true);
    }
    $('input[name="iata"]').on('change', (e) => {
      if (window.digitalData && window.digitalData.search && window.digitalData.search.searchInfo && window.digitalData.search.searchInfo.iataCode) {
        window.digitalData.search.searchInfo.iataCode = $(e.currentTarget).val();
      }
    });
    this.alignPricesToPackagesOnModal();
  }

  fetchPackages(res) {
    let CurrencyCode = '';
    if (window.digitalData && window.digitalData.booking && window.digitalData.booking.bookingInfo && window.digitalData.booking.bookingInfo.currencyCode){
      CurrencyCode = window.digitalData.booking.bookingInfo.currencyCode;
    }
    let data = {
      "checkin_date": formatDateForBWSAvailability(this.criteria.checkInDate),
      "checkout_date": formatDateForBWSAvailability(this.criteria.checkOutDate),
      "rate_plan": req_rate_plan,
      "room_type_code": req_room_type,
      "propertyId": this._params.propertyId,
      "adults": this.criteria.adults,
      "children":this.criteria.children,
      "childAge":this.criteria.childAge,
      "language":_LOCALE_,
      "noOfRooms":this.criteria.rooms,
      "requested_currency_code": CurrencyCode
    };

    let addonPackages = getLocalStorage('addon-packages') || {};
    this._data.packages = addonPackages;
    if(!$_PAGE_.is('.packages-page') && $_PAGE_.is('.booking-page') && addonPackages.length > 0){
      this.populatePackageView();
      let addedPackage = getSessionStorage('added-package') || {};
      this.updateToataAmountAftertax(addedPackage);
    }else{
      if(data && _isNotNull(data.propertyId) && data.propertyId !== ''){
        callService('getPackages', $.param(data), 'GET').then((res) => {
          if((res && res.status === 'OK' || res.services) && this.addonsCategories && this.addonsCategories.length >= 0) {
            this._data.packages = res.services;
            setLocalStorage('addon-packages',JSON.stringify(res.services));
            this.populatePackageView();
            let addedPackage = getSessionStorage('added-package') || {};
            this.updateToataAmountAftertax(addedPackage);
          }
        });
      }else{
        return;
      }
    }
  }

  initParams() {
    let p = this._params,
      sc = getCriteria();

    p = $.extend(true, {}, sc);
    if (property_country_code != 'US') {
      $('.reservation-calc').hide();
    }
    p.propertyId = overview_propertyId;
    p.orsId = overview_orsId;
    p.altPropId = overview_orsId;
    p.brandId = overview_brandId;
    p.rateCode = req_rate_plan;
    p.ratePlan = '';
    //check-in and check-out logic
    p.checkInDate = formatDateForBWSAvailability(p.checkInDate);
    p.checkOutDate = formatDateForBWSAvailability(p.checkOutDate);

    $.extend(true, this._params, p);
  }

  backToPackagePage(){
    window.onload = () => {
      // Push 2 states to stack. One for current page, one for if user clicks back button
      history.pushState('catch-back', null, null);
      history.pushState('currentstate', null, null);
      window.addEventListener('popstate', (e) => {
        // If user goes back, state will become 'catch-back'.
        // If user refreshes or logs out, state will be null.
        if (e.state === 'catch-back') {
          // Currentstate has been removed from history by back click. Push replacement to stack and alert user of error.
          history.pushState('newcurrentstate', null, null);
          goToPackages(req_room_type, req_rate_plan, req_rate_cc, window.req_auto_enroll, window.req_qual_points,
            '', window.req_after_tax, req_roomImg);
        }
      });
    };
  }

  populatePackageView() {
    let packages = this._data.packages;
    $('.rooms-rates-listing').children('div:not(:first)').remove();
    if (!this.addonsCategories || !this.addonsCategories.length) {
      return;
    }
    $.each(Object.entries(this.addonsCategories[0])
      .filter(([,category])=> (JSON.parse(category)[_LOCALE_] !== undefined && JSON.parse(category)[_LOCALE_] !== ''))
      .sort(([,categoryA],[,categoryB])=> {
        return JSON.parse(categoryA)[_LOCALE_].toString().localeCompare(JSON.parse(categoryB)[_LOCALE_]);
      })
      .reduce((result, [key, value]) => ({ ...result, [key]: value }), {}),(counter,category)=>{
      let pacakageindex = 0;

      let isCategoryHasPackage;
      try{
        isCategoryHasPackage = packages.reduce((result , item) => {
          return item && item.comments && item.comments.category === JSON.parse(category)[_LOCALE_] ? item : result;
        },null);
      }catch(error){
        return;
      }
      if(isCategoryHasPackage){
        let $category = $('.listing.packages-add-on__item.clone').clone()
          .removeClass('clone');

        $('.packages-add-on.intro-title',$category).html(JSON.parse(category)[_LOCALE_]);

        if(packages && packages.length > 0) {
          $_PAGE_.removeClass('loading');
          $.each(packages.sort((packageA,packageB)=> {
            return packageA.comments.title.toString().localeCompare(packageB.comments.title);
          }).sort((packageA,packageB)=>{
            return parseFloat(packageA.prices[0].amountAfterTax) - parseFloat(packageB.prices[0].amountAfterTax) ;
          }), (counter, packageAddons) => {
            if(packageAddons.comments.category === JSON.parse(category)[_LOCALE_]){
              pacakageindex++;

              let $pg = $('.rooms-slide .room.clone',$category).clone()
                .removeClass('clone');
              // this is adding class to anything over 3 for room view
              if (pacakageindex > 2) {
                $pg.hide();
              }
              $('.package-title .name-toggle', $pg).html($('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text());

              $pg.attr('visible-index', pacakageindex);

              $('select.package-quantity',$pg).empty();
              $('select.package-quantity',$pg).addClass('select-' + this.slugify(packageAddons.comments.title));

              $('select.package-quantity.select-' + this.slugify(packageAddons.comments.title),$pg).on('change',(event)=>{
                const addedPackage = getSessionStorage('added-package') || {};
                if(addedPackage[this.slugify(packageAddons.comments.title)]){
                  this.updatePackageToBooking(packageAddons, packageAddons.prices[0], $(event.target).val());
                  this.tostMessage.text(this.tostMessage.data('addOnQuantityUpdateToastLabel'));
                  this.undoForRemovedPackage.hide();
                  this.lastEventToUndo = {'event': 'CHANGE',...addedPackage[this.slugify(packageAddons.comments.title)]};
                  this.showPackageQuantityUpdateToast();
                }
              });

              if(packageAddons.quantity <= 1){
                $('select.package-quantity',$pg).parents('.input-field-container').hide();

              }else if(packageAddons.quantity > 1){
                $('span.package-total-quantity',$pg).removeClass('hidden');
                $('span.package-total-stay',$pg).addClass('hidden');
              }
              for(let i = 1; i <= packageAddons.quantity; i++){
                $('select.package-quantity',$pg).append(`<option value="${i}">${i}</option>`);
              }
              const addedPackage = getSessionStorage('added-package') || {};
              if(addedPackage[this.slugify(packageAddons.comments.title)]){
                $('select.package-quantity',$pg).prop('selectedIndex',(addedPackage[this.slugify(packageAddons.comments.title)].qty - 1));
              }
              this.popPackageRates(packageAddons, $pg, packageAddons.prices[0]);
              $pg.find('li.see-more').remove();
              $('.rooms-slide',$category).append($pg);

              if ($pg.find('.packages-select select option').length > 1) {
                this.updateSelectDropdowns($pg[0].querySelector('.packages-select'),packageAddons);
              }
            }
          });
          if(pacakageindex > 2){
            let $pgseemore = $('.rooms-slide .room.clone',$category).clone()
              .removeClass('clone').addClass('see-more');
            $pgseemore.find('li.see-more').show();
            $pgseemore.find('li.clone').remove();
            let seeMore =  $pgseemore.find('li.see-more');
            seeMore.find('.see-more-text').text(seeMore.find('.see-more-text').text().toUpperCase());
            seeMore.click((e) => {
              e.preventDefault();
              if (seeMore.hasClass('see-less')) {
                $('.rooms-slide .room', $category).not('.clone,.see-more')
                  .slice(2)
                  .hide();
                let more = seeMore.find('.see-more-text').data('more');
                seeMore.find('.see-more-text').text(more.toUpperCase());
                seeMore.removeClass('see-less');
              } else {
                $('.rooms-slide .room', $category).not('.clone,.see-more')
                  .show();
                let less = seeMore.find('.see-more-text').data('less');
                seeMore.find('.see-more-text').text(less.toUpperCase());
                seeMore.addClass('see-less');
                this.handlePackagesFooter();
              }
            });
            $('.rooms-slide',$category).append($pgseemore);
          }

        }
        $('.packages-add-on__listing').append($category);
      }
    });
    this.populatePackageOnBooking();
    this.adjustPackageDescriptionOverlay();
    window.addEventListener('resize', () => {
      this.adjustPackageDescriptionOverlay();
    });
  }

  adjustPackageDescriptionOverlay() {
    document.querySelectorAll(".package-description .description").forEach(blurb => {
      if (blurb.offsetHeight >= 127) {
        blurb.classList.add('description__overlay');
        blurb.style.setProperty('--descriptionOverlayWidth', blurb.offsetWidth + 'px');
      } else {
        blurb.classList.remove('description__overlay');
      }
    });
  }

  closeAllSelect() {
    $(".select-items").addClass("select-hide");
  }

  updateSelectDropdowns(packageSelect) {
    let choice;
    let selectElement = packageSelect.querySelector("select");
    let choices = document.createElement("DIV");
    choices.classList.add("select-items", "select-hide");

    for (let counter = 0; counter < selectElement.length; counter++) {
      choice = document.createElement("DIV");
      choice.innerHTML = `<span>` + selectElement.options[counter].innerHTML + `</span>`;
      selectElement.options[counter].toggleAttribute("hidden");

      choice.addEventListener("click", function(e) {
        let currentChoice = this.parentNode.parentNode.querySelector("select");
        let selectText = this.parentNode.previousSibling;

        for (let counter = 0; counter < currentChoice.length; counter++) {
          if (currentChoice.options[counter].innerHTML == this.innerText) {
            currentChoice.selectedIndex = counter;
            selectText.innerHTML = this.innerText;
            currentChoice.dispatchEvent(new Event('change'));
            break;
          }
        }
      });
      choices.appendChild(choice);
    }

    packageSelect.appendChild(choices);
    selectElement.addEventListener("click", (e) => {
      selectElement.blur();
      e.stopPropagation();
      this.closeAllSelect();
      packageSelect.querySelector('.select-hide').classList.toggle("select-hide");
      document.addEventListener("click", () => {
        this.closeAllSelect();
      }, { once: true });
    });
  }

  triggerBooking(){
    $('.search-button-container.booking-button').on('click',()=>{
      this.goToBooking();
    });
  }

  totalPrice(packageAddons,price){
    switch(packageAddons.servicePricingType){
    case "Per Room Quantity Per Night":
      return this.totalStayPrice(packageAddons);

    case "Per Person Occupancy Per Night":
      return parseFloat(this.totalStayPrice(packageAddons)) * (parseFloat(this.criteria.adults) + parseFloat(this.criteria.children));

    case "Per Adult Occupancy Per Night":
      return parseFloat(this.totalStayPrice(packageAddons)) * parseFloat(this.criteria.adults);

    case "Per Child Occupancy Per Night":
      return parseFloat(this.totalStayPrice(packageAddons)) * parseFloat(this.criteria.children);

    default:
      return this.totalStayPrice(packageAddons);
    }
  }

  totalStayPrice(packageAddons){
    return packageAddons.prices.reduce((totlaPriceAmountAfterTax,eachDayPrice)=>totlaPriceAmountAfterTax += parseFloat(eachDayPrice.amountAfterTax),0);
  }

  undoUpdate(){
    if(this.lastEventToUndo && this.lastEventToUndo.event){
      if(this.lastEventToUndo.event === 'CHANGE'){
        this.removePackageToBooking(this.lastEventToUndo.packageAddons);
        this.addPackageToBooking(this.lastEventToUndo.packageAddons,this.lastEventToUndo.price,this.lastEventToUndo.qty);
        $('.select-' + this.slugify(this.lastEventToUndo.packageAddons.comments.title)).prop('selectedIndex',(this.lastEventToUndo.qty - 1));
        this.lastEventToUndo = {};
      } else if(this.lastEventToUndo.event === 'ADD'){
        this.removePackageToBooking(this.lastEventToUndo.packageAddons);
        this.lastEventToUndo = {};

      }else if(this.lastEventToUndo.event === 'REMOVE'){
        this.addPackageToBooking(this.lastEventToUndo.packageAddons,this.lastEventToUndo.price,this.lastEventToUndo.qty);
        $('.select-' + this.slugify(this.lastEventToUndo.packageAddons.comments.title)).prop('selectedIndex',(this.lastEventToUndo.qty - 1));
        this.lastEventToUndo = {};
      }
      $("#packageQuantityUpdate .toast-close__button").click();
    }
  }

  popPackageRates(packageAddons, $pg, price) { // ratesecs is each object in room.rates array, $pg is rooms element, room is entire room object
    // ratesecs is each object in room.rates array, $pg is rooms element, room is entire room object
    this.rateCounter += 1;



    let $rrc = $('.rate-list', $pg).find('.rate.clone')
      .removeClass('clone');

    $('.room-title .price-toggle', $rrc).text(getCurrencyMapping(price.currencyCode) + Number(convertToCurrency(this.totalPrice(packageAddons,price.amountAfterTax),price.currencyCode)).toFixed(2).toString() + ' ' + getSelectedCurencyCode(price.currencyCode));
    if(packageAddons.comments.image){
      let imageDiv = $rrc.find('.packages-add-on__single-package-image-block div.packages-add-on__single-package-image');
      imageDiv.css({ "background-image": 'url(' + packageAddons.comments.image + ')' });
    }else{
      $rrc.find('.packages-add-on__single-package-image-block').remove();
    }
    $('.package-description .package-description', $rrc).html($('<div/>',{html: packageAddons.comments.description}).text());

    $('.package-details-open-modal', $rrc).on('click', () => {
      this.populateModelData(packageAddons, price);
    });

    //add-package-tobooking

    $('.from.book-text', $rrc).addClass('button-' + this.slugify(packageAddons.comments.title));
    let addedPackage = getSessionStorage('added-package') || {};

    if(addedPackage[this.slugify(packageAddons.comments.title)]){
      $('.from.book-text', $rrc).text($('.from.book-text', $rrc).data('removeButtonLabel'));
    }
    $('.from.book-text', $rrc).on('click',(e)=>{
      $(e.target).parents('.add-package-tobooking').trigger('click');
    });
    this.showPackageQuantityUpdateToastPosition();
    window.addEventListener('resize', () => {
      this.showPackageQuantityUpdateToastPosition();
    });
    $('.add-package-tobooking', $rrc).on('click', (e) => {
      let buttom =  $(e.target).find('.from.book-text');
      let qty = $(e.target).parents('form').find('select > option:selected').val() || 1;
      let addedPackage = getSessionStorage('added-package') || {};
      if(buttom.text() == buttom.data('addButtonLabel')){
        this.tostMessage.text(this.tostMessage.data('addOnAddedToastLabel'));
        this.undoForRemovedPackage.hide();
        this.lastEventToUndo = {'event': 'ADD','packageAddons':packageAddons,'price':price,'qty':qty};
        this.addPackageToBooking(packageAddons, price,qty);

        buttom.text(buttom.data('removeButtonLabel'));
        this.showPackageQuantityUpdateToast();
      } else if(buttom.text() == buttom.data('removeButtonLabel')){
        this.tostMessage.text(this.tostMessage.data('addOnRemoveToastLabel'));
        this.undoForRemovedPackage.show();
        this.lastEventToUndo = {'event': 'REMOVE',...addedPackage[this.slugify(packageAddons.comments.title)]};
        this.removePackageToBooking(packageAddons);
        $('.select-' + this.slugify(packageAddons.comments.title)).prop('selectedIndex',0);
        this.showPackageQuantityUpdateToast();
      }
    });

    //Reserve button binding activate

    $rrc.show();

  }

  showPackageQuantityUpdateToastPosition(){
    if (isDesktopWidth()) {
      document.querySelector("#packageQuantityUpdate").style.left = document.querySelector('.container.component').getBoundingClientRect().x + 100 + "px";
    } else if (isTabletWidth()) {
      document.querySelector("#packageQuantityUpdate").style.left = "36px";

    } else if (isMobileWidth()) {
      document.querySelector("#packageQuantityUpdate").style.left = document.querySelector('.container.component').getBoundingClientRect().x;
    }

  }

  showPackageQuantityUpdateToast(){
    this.positionPackageQuantityUpdateToast();
    clearTimeout(this.closePackageQuantityUpdateToast);
    document.querySelector("#packageQuantityUpdate").style.display = "none";
    window.clearTimeout(this.closePackageQuantityUpdateToast);
    if(!$("#packageQuantityUpdate").is(':visible')){
      document.querySelector("#packageQuantityUpdate").style.display = "";
    }
    this.closePackageQuantityUpdateToast = window.setTimeout(() => {
      $("#packageQuantityUpdate .toast-close__button").click();
    }, 5000);
  }

  hidePackageQuantityUpdateToast(){
    const toastElement = document.querySelector("#packageQuantityUpdate");
    $("#packageQuantityUpdate .toast-close__button").click(() => {
      toastElement.classList.remove("fade-in");
      toastElement.classList.add("fade-out");
      window.setTimeout(() => {
        toastElement.style.display = "none";
        toastElement.classList.remove("fade-out");
        toastElement.classList.add("fade-in");
      }, 400);
    });
    $('.toast-controls .toast-undo').click(()=>{
      this.undoUpdate();
    });
  }

  positionPackageQuantityUpdateToast(){
    const toastBottomOffset = 20;
    if (isDesktopWidth() && window.innerHeight >= $('footer')[0].getBoundingClientRect().top) {
      $('#packageQuantityUpdate').css("bottom", window.innerHeight - $('footer')[0].getBoundingClientRect().top + toastBottomOffset + 'px');
    } else if ($_PAGE_.is('.packages-page') && (isMobileWidth() || (isTabletWidth() && window.innerHeight >= $('.button-container')[0].getBoundingClientRect().top))) {
      $('#packageQuantityUpdate').css("bottom", window.innerHeight - $('.button-container')[0].getBoundingClientRect().top + toastBottomOffset + 'px');
    } else if ($_PAGE_.is('.booking-page') && (isMobileWidth() || isTabletWidth()) && window.innerHeight >= $('footer')[0].getBoundingClientRect().top) {
      $('#packageQuantityUpdate').css("bottom", window.innerHeight - $('footer')[0].getBoundingClientRect().top + toastBottomOffset + 'px');
    } else {
      $('#packageQuantityUpdate').css("bottom", "");
    }
  }

  handlePackageQuantityUpdateToast() {
    this.hidePackageQuantityUpdateToast();
    if($_PAGE_.is('.packages-page') || $_PAGE_.is('.booking-page')){
      window.addEventListener('resize', () => {
        this.positionPackageQuantityUpdateToast();
      });
      window.addEventListener('scroll', () => {
        this.positionPackageQuantityUpdateToast();
      });
    }
  }

  slugify(str){
    return str
      .toLowerCase()
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

  getAllNightTotalPrice(packageAddons, amountAfterTax , qty){
    this.criteria = getSessionCriteria();
    return this.totalPrice(packageAddons,amountAfterTax) * parseFloat(qty) * parseFloat(this.criteria.rooms);
  }

  totalQuantity(packageAddons,qty){
    return qty;
  }

  totalPakageNightPriceQuantity(packageAddons,price){
    const addedPackage = getSessionStorage('added-package') || {};
    let qty = addedPackage[this.slugify(packageAddons.comments.title)] && addedPackage[this.slugify(packageAddons.comments.title)].qty ? parseFloat(addedPackage[this.slugify(packageAddons.comments.title)].qty) : $('.select-' + this.slugify(packageAddons.comments.title)).length > 0 ? parseFloat($('.select-' + this.slugify(packageAddons.comments.title)).prop('selectedIndex')) + 1 : 1;
    switch(packageAddons.servicePricingType){
    case "Per Room Quantity Per Night":
      return parseFloat(price) * parseFloat(this.criteria.rooms) * qty;

    case "Per Person Occupancy Per Night":
      return parseFloat(price) * (parseFloat(this.criteria.adults) + parseFloat(this.criteria.children)) *  parseFloat(this.criteria.rooms) * qty;

    case "Per Adult Occupancy Per Night":
      return parseFloat(price) * parseFloat(this.criteria.adults) * parseFloat(this.criteria.rooms) * qty;

    case "Per Child Occupancy Per Night":
      return parseFloat(price) * parseFloat(this.criteria.children) * parseFloat(this.criteria.rooms) * qty;

    default:
      return parseFloat(price) * qty;
    }
  }

  addPackageToBooking(packageAddons, price, qty) {
    $('.' + this.slugify(packageAddons.comments.title)).remove();
    $('.package-list-rate-summary, .package-list-rate-detail').removeClass('hidden');
    if($('.button-' + this.slugify(packageAddons.comments.title))){
      $('.button-' + this.slugify(packageAddons.comments.title)).text($('.button-' + this.slugify(packageAddons.comments.title)).data('removeButtonLabel'));
    }
    let property = $('.package-list-rate-summary').data();
    $('.package-list-rate-summary').append(`
      <div class="` + this.slugify(packageAddons.comments.title) + ` rate-aspect clearfix" >
      <div class="price-name">
        <div class="name-main room-description">${$('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text()}<span class="package-quantity">${' (' + this.totalQuantity(packageAddons,qty) + 'x)' }</span></div>

        <div class="name-secondary">
          <a class="rate-details-link details" data-category="${packageAddons.comments.category}">${property.detailsButtonLabel}</a> | <a  data-category="${packageAddons.comments.category}" class="remove-package-ratesummary rate-details-link remove" >${property.removeButtonLabel}</a>
        </div>
      </div>

      <div class="price-value">
        <div class="subtotal">
          <span class="val">
            <span class="currency-symbol">` + getCurrencyMapping(price.currencyCode,!$_PAGE_.is('.packages-page')) + `</span>
             ${ convertToCurrency(parseFloat(this.getAllNightTotalPrice(packageAddons,price.amountAfterTax,qty)),price.currencyCode,!$_PAGE_.is('.packages-page'))}
            <span class="currency-code">` + ' ' + getSelectedCurencyCode(price.currencyCode,!$_PAGE_.is('.packages-page')) + `</span>
          </span>
        </div>
      </div>
    </div>
  `);


    $('.rd.package-nights-header').append(`<li class="${this.slugify(packageAddons.comments.title)}">${$('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text()}</li>`);
    $('.rd.package-nights-value').append(`<li class="${this.slugify(packageAddons.comments.title)}" >${printDollarAmount(this.getAllNightTotalPrice(packageAddons,price.amountAfterTax,qty) ,price.currencyCode,true)}</li>`);

    if(packageAddons.guarantee && packageAddons.guarantee.length > 0){
      packageAddons.guarantee.map((msg)=>{
        $('#rateSummaryDetail').find('.package-guarantee-disclaimer')
          .append(`<span class="cancel-policy ${this.slugify(packageAddons.comments.title)} "><br/><b>${$('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text()}:</b> ${msg.guaranteeDescription}</span>`);
      });
      if($('#rateSummaryDetail').find('.package-guarantee-disclaimer > .cancel-policy').length){
        $('#rateSummaryDetail').find('.package-guarantee-disclaimer').show();
      }
    }
    if(packageAddons.cancelPenalty && packageAddons.cancelPenalty.length > 0){
      packageAddons.cancelPenalty.map((msg)=>{
        $('#rateSummaryDetail').find('.package-cancelPenalty-disclaimer')
          .append(`<span class="cancel-policy ${this.slugify(packageAddons.comments.title)} "><br/><b>${$('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text()}:</b>${msg.penaltyDescription}</span>`);
      });
      if($('#rateSummaryDetail').find('.package-cancelPenalty-disclaimer > .cancel-policy').length){
        $('#rateSummaryDetail').find('.package-cancelPenalty-disclaimer').show();
      }
    }
    $('.package-list-rate-summary > .' + this.slugify(packageAddons.comments.title)).find('.name-secondary > a.rate-details-link.details').on('click',(e) => {
      this.populateModelData(packageAddons, price);
      $('#packageSummaryDetail').modal('show');
    });

    $('.package-list-rate-summary > .' + this.slugify(packageAddons.comments.title)).find('.name-secondary > a.remove-package-ratesummary.remove').on('click',(e)=> {
      let addedPackage = getSessionStorage('added-package') || {};
      this.tostMessage.text(this.tostMessage.data('addOnRemoveToastLabel'));
      this.undoForRemovedPackage.show();
      this.lastEventToUndo = {'event': 'REMOVE',...addedPackage[this.slugify(packageAddons.comments.title)]};
      this.removePackageToBooking(packageAddons) ;
      if($('.select-' + this.slugify(packageAddons.comments.title))){
        $('.select-' + this.slugify(packageAddons.comments.title)).prop('selectedIndex',0);
      }
      this.showPackageQuantityUpdateToast();
    });




    let addedPackage = getSessionStorage('added-package') || {};
    addedPackage[this.slugify(packageAddons.comments.title)] = {
      'packageAddons' : packageAddons,
      'price':price,
      'qty': qty
    };
    setSessionStorage('added-package',addedPackage,true);
    let height = $('div.' + this.slugify(packageAddons.comments.title)).outerHeight();
    if($('.rate-summary-content') && RateSummaryCurrent.opts.stick){
      let stop = RateSummaryCurrent.opts.stick.top + (height + 15);
      $('.rate-summary-content').css('top', stop + 'px');
      if(!$('.rate-summary-content').is('.stick') && $('.rate-summary-content').css('top') !== 'auto'){
        //$('.rate-summary-content').css('top',  (RateSummaryCurrent.opts.stick.top - (height + 15)) + 'px');
        $('.rate-summary-content').css('top', (parseFloat($('.rate-summary-content').css('top')) - (height + 15)) + 'px');
      }
      RateSummaryCurrent.opts.stick.top = stop;
      if($('.rate-summary-content').is('.stick') && $('.rate-summary-content').css('top') !== 'auto'){
        $('.rate-summary-content').css('top', stop + 'px');
      }
    }
    if($('.rs-book-with-conf') && RateSummaryCurrent.stickyOptsBookWithConfidence.stick){
      let btop = RateSummaryCurrent.stickyOptsBookWithConfidence.stick.css.top + (height + 15);
      if(!$('.rs-book-with-conf').is('.stick') && $('.rate-summary-content').css('top') !== 'auto'){
        $('.rs-book-with-conf').css('top',  (RateSummaryCurrent.opts.stick.top - (height + 15)) + 'px');
      }
      RateSummaryCurrent.stickyOptsBookWithConfidence.stick.css.top = btop;
      if($('.rs-book-with-conf').is('.stick') && $('.rs-book-with-conf').css('top') !== 'auto'){
        $('.rs-book-with-conf').css('top', btop + 'px');
      }
    }
    this.updateToataAmountAftertax(addedPackage);

  }

  updatePackageToBooking(packageAddons, price, qty) {
    let property = $('.package-list-rate-summary').data();
    $('.package-list-rate-summary .' + this.slugify(packageAddons.comments.title)).html(`
      <div class="price-name">
        <div class="name-main room-description">${$('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text()}<span class="package-quantity">${' (' + this.totalQuantity(packageAddons,qty) + 'x)' }</span></div>

        <div class="name-secondary">
          <a class="rate-details-link details" data-category="${packageAddons.comments.category}">${property.detailsButtonLabel}</a> | <a  data-category="${packageAddons.comments.category}" class="remove-package-ratesummary rate-details-link remove" >${property.removeButtonLabel}</a>
        </div>
      </div>

      <div class="price-value">
        <div class="subtotal">
          <span class="val">
            <span class="currency-symbol">` + getCurrencyMapping(price.currencyCode,!$_PAGE_.is('.packages-page')) + `</span>
             ${ convertToCurrency(parseFloat(this.getAllNightTotalPrice(packageAddons,price.amountAfterTax,qty)),price.currencyCode,!$_PAGE_.is('.packages-page'))}
            <span class="currency-code">` + ' ' + getSelectedCurencyCode(price.currencyCode,!$_PAGE_.is('.packages-page')) + `</span>
          </span>
        </div>
      </div>
  `);
    $('.rd.package-nights-header .' + this.slugify(packageAddons.comments.title)).html($('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text());
    $('.rd.package-nights-value .' + this.slugify(packageAddons.comments.title)).html(printDollarAmount(this.getAllNightTotalPrice(packageAddons,price.amountAfterTax,qty) ,price.currencyCode,true));

    $('.package-list-rate-summary > .' + this.slugify(packageAddons.comments.title)).find('.name-secondary > a.rate-details-link.details').on('click',(e) => {
      this.populateModelData(packageAddons, price);
      $('#packageSummaryDetail').modal('show');
    });

    $('.package-list-rate-summary > .' + this.slugify(packageAddons.comments.title)).find('.name-secondary > a.remove-package-ratesummary.remove').on('click',(e)=> {
      let addedPackage = getSessionStorage('added-package') || {};
      this.tostMessage.text(this.tostMessage.data('addOnRemoveToastLabel'));
      this.undoForRemovedPackage.show();
      this.lastEventToUndo = {'event': 'REMOVE',...addedPackage[this.slugify(packageAddons.comments.title)]};
      this.removePackageToBooking(packageAddons) ;
      if($('.select-' + this.slugify(packageAddons.comments.title))){
        $('.select-' + this.slugify(packageAddons.comments.title)).prop('selectedIndex',0);
      }
      this.showPackageQuantityUpdateToast();
    });

    let addedPackage = getSessionStorage('added-package') || {};
    addedPackage[this.slugify(packageAddons.comments.title)] = {
      'packageAddons' : packageAddons,
      'price':price,
      'qty': qty
    };
    setSessionStorage('added-package',addedPackage,true);
    this.updateToataAmountAftertax(addedPackage);

  }


  addPackagefromAEM(packageAddons, price, qty) {
    $('.' + this.slugify(packageAddons.comments.title)).remove();
    $('.package-list-rate-summary, .package-list-rate-detail').removeClass('hidden');
    let property = $('.package-list-rate-summary').data();
    $('.package-list-rate-summary').append(`
      <div class="` + this.slugify(packageAddons.comments.title) + ` rate-aspect clearfix" >
      <div class="price-name">
        <div class="name-main room-description">${$('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text()}<span class="package-quantity hidden">${' (' + qty + 'x)' }</span></div>

        <div class="name-secondary">
        <a class="rate-details-link details" data-category="${packageAddons.comments.category}">${property.detailsButtonLabel}</a>
        </div>
      </div>

      <div class="price-value">
        <div class="subtotal">
          <span class="val">
            <span class="currency-symbol">` + getCurrencyMapping(price.currencyCode,!$_PAGE_.is('.packages-page')) + `</span>
              ${ convertToCurrency(parseFloat(price.amountAfterTax) * packageAddons.prices.length ,price.currencyCode,!$_PAGE_.is('.packages-page'))}
            <span class="currency-code">` + ' ' + getSelectedCurencyCode(price.currencyCode,!$_PAGE_.is('.packages-page')) + `</span>
          </span>
        </div>
      </div>
    </div>
  `);


    $('.rd.package-nights-header').append(`<li class="${this.slugify(packageAddons.comments.title)}">${$('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text()}</li>`);
    $('.rd.package-nights-value').append(`<li class="${this.slugify(packageAddons.comments.title)}" >${printDollarAmount(parseFloat(price.amountAfterTax) * packageAddons.prices.length ,price.currencyCode,true)}</li>`);


    if(packageAddons.guarantee && packageAddons.guarantee.length > 0){
      packageAddons.guarantee.map((msg)=>{
        $('#rateSummaryDetail').find('.package-guarantee-disclaimer')
          .append(`<span class="cancel-policy ${this.slugify(packageAddons.comments.title)} "><br/><b>${$('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text()}:</b> ${msg.guaranteeDescription}</span>`);
      });
      if($('#rateSummaryDetail').find('.package-guarantee-disclaimer > .cancel-policy').length){
        $('#rateSummaryDetail').find('.package-guarantee-disclaimer').show();
      }
    }
    if(packageAddons.cancelPenalty && packageAddons.cancelPenalty.length > 0){
      packageAddons.cancelPenalty.map((msg)=>{
        $('#rateSummaryDetail').find('.package-cancelPenalty-disclaimer')
          .append(`<span class="cancel-policy ${this.slugify(packageAddons.comments.title)} "><br/><b>${$('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text()}:</b> ${msg.penaltyDescription}</span>`);
      });
      if($('#rateSummaryDetail').find('.package-cancelPenalty-disclaimer > .cancel-policy').length){
        $('#rateSummaryDetail').find('.package-cancelPenalty-disclaimer').show();
      }
    }

    $('.package-list-rate-summary > .' + this.slugify(packageAddons.comments.title)).find('.name-secondary > a.rate-details-link.details').on('click',(e) => {
      this.populateModelData(packageAddons, price, true);
      $('#packageSummaryDetail').modal('show');
    });

    this.updateToataAmountAftertaxFromAEM(reservationDataFromAEM.packages);
  }

  updateToataAmountAftertaxFromAEM(addedPackages){
    let toatalAmountAftertax = parseFloat(reservationDataFromAEM.TotalAfterTax);
    let packCurrencyCode = '';
    if (window.digitalData && window.digitalData.booking && window.digitalData.booking.bookingInfo && window.digitalData.booking.bookingInfo.currencyCode){
      packCurrencyCode =  window.digitalData.booking.bookingInfo.currencyCode;
    }
    $.each(addedPackages, (counter, addedpackage)=>{
      toatalAmountAftertax += (this.totalPrice(addedpackage,addedpackage.prices[0].amountAfterTax));
      packCurrencyCode = addedpackage.prices[0].currencyCode;
    });
    if(toatalAmountAftertax && packCurrencyCode){
      this.toatalAmountAftertaxwithPackage = toatalAmountAftertax;
    }

  }

  updateToataAmountAftertax(addedPackages){
    let bookingData =  ReservationHandler.getBookData();
    let toatalAmountAftertax = bookingData.totatax ? parseFloat(bookingData.totatax.replaceAll(',','')) : 0;
    let packCurrencyCode = '';
    if (window.digitalData && window.digitalData.booking && window.digitalData.booking.bookingInfo && window.digitalData.booking.bookingInfo.currencyCode){
      packCurrencyCode =  window.digitalData.booking.bookingInfo.currencyCode;
    }
    let packageCartVal = 0;
    let packageCartQuantity = 0;
    $.each(addedPackages, (counter, addedpackage)=>{
      toatalAmountAftertax += (this.getAllNightTotalPrice(addedpackage.packageAddons,addedpackage.price.amountAfterTax,addedpackage.qty));
      
      packageCartVal += (this.getAllNightTotalPrice(addedpackage.packageAddons,addedpackage.price.amountBeforeTax,addedpackage.qty));
      packageCartQuantity += parseInt(addedpackage.qty);
      packCurrencyCode = addedpackage.price.currencyCode;
    });
    window.digitalData.packages.packageCartVal = parseFloat(packageCartVal).toFixed(2);
    window.digitalData.packages.packageCartQuantity = packageCartQuantity;
    if(toatalAmountAftertax && packCurrencyCode){
      this.toatalAmountAftertaxwithPackage = toatalAmountAftertax;
      $('.wyn-uplift-content .wyn-uplift-price').attr({
        'data-up-price-value':this.toatalAmountAftertaxwithPackage * 100
      });
      //BookingSummary.handleTotal(toatalAmountAftertax, packCurrencyCode);
      
      rateDetails.udatePackakageTotal(packageCartVal);

      // $('.rate-summary-lightbox')
      //   .find('.cash-total')
      //   .html(printDollarAmount(toatalAmountAftertax, packCurrencyCode, true))
      //   .show();
      if($_PAGE_.is('.booking-page') && !$_PAGE_.is('.packages-page') && addedPackages.length){
        $('[id=myc-wallet-modal]').each((i,e)=>{
          e.remove();
        });
      }
      this.initBNPLUplift(toatalAmountAftertax, packCurrencyCode);
    }

  }

  initBNPLUplift(toatalAmountAftertax = '' ,packCurrencyCode = ''){
    if(BNPLUplift.handleCurrencyConverter()){
      try {
        if (window.digitalData && window.digitalData.booking && window.digitalData.booking.bookingInfo && window.digitalData.booking.bookingInfo.currencyCode) {
          packCurrencyCode = packCurrencyCode || window.digitalData.booking.bookingInfo.currencyCode;
        }
        toatalAmountAftertax = toatalAmountAftertax || parseFloat(window.req_after_tax);
        if (!isGoFreeRate(req_rate_plan) && !isSMORate(req_rate_plan) && !isBNPLEX(getSearchOverview())) {
          if (packCurrencyCode === 'USD') {
            $('.wyn-uplift-content .wyn-uplift-currency-symbol').show();
            $('.wyn-uplift-content .wyn-uplift-currency-value').removeClass('notUSD');
            $('.wyn-uplift-content .wyn-uplift-currency-symbol').text('$');
          }
          BNPLUplift.handleConfig({
            currencyCode: packCurrencyCode,
            subtotal: toatalAmountAftertax,
            noOfRooms: this.criteria.rooms,
            propertyName: getName(),
            checkinDate: formatDateForUplift(this.criteria.checkInDate),
            checkoutDate: formatDateForUplift(this.criteria.checkOutDate),
            checkout: (!$_PAGE_.is('.packages-page') && $_PAGE_.is('.booking-page'))
          });
        }
        if (window.Uplift) {
          window.Uplift.Payments.load(BNPLUplift.buildOrderInfo());
        }
      } catch (err) {
        return;
      }
    }
  }

  removePackageToBooking(packageAddons) {

    let height = $('div.' + this.slugify(packageAddons.comments.title)).outerHeight();
    $('.' + this.slugify(packageAddons.comments.title)).remove();
    if($('.button-' + this.slugify(packageAddons.comments.title))){
      $('.button-' + this.slugify(packageAddons.comments.title)).text($('.button-' + this.slugify(packageAddons.comments.title)).data('addButtonLabel'));
    }
    if($('.package-list-rate-summary > div').length == 0){
      $('.package-list-rate-summary, .package-list-rate-detail').addClass('hidden');
    }
    if(!$('#rateSummaryDetail').find('.package-guarantee-disclaimer > .cancel-policy').length){
      $('#rateSummaryDetail').find('.package-guarantee-disclaimer').hide();
    }
    if(!$('#rateSummaryDetail').find('.package-cancelPenalty-disclaimer > .cancel-policy').length){
      $('#rateSummaryDetail').find('.package-cancelPenalty-disclaimer').hide();
    }
    // Remove cookies and html element
    let addedPackage = getSessionStorage('added-package') || {};
    delete addedPackage[this.slugify(packageAddons.comments.title)];
    setSessionStorage('added-package',addedPackage,true);
    if($('.rate-summary-content') && RateSummaryCurrent.opts.stick){
      let stop = RateSummaryCurrent.opts.stick.top - (height + 15);
      $('.rate-summary-content').css('top', stop + 'px');
      if(!$('.rate-summary-content').is('.stick') && $('.rate-summary-content').css('top') !== 'auto'){
        //$('.rate-summary-content').css('top', (RateSummaryCurrent.opts.stick.top - (height + 15)) + 'px');
        $('.rate-summary-content').css('top', (parseFloat($('.rate-summary-content').css('top')) + (height + 15)) + 'px');
      }
      RateSummaryCurrent.opts.stick.top = stop;
      if($('.rate-summary-content').is('.stick') && $('.rate-summary-content').css('top') !== 'auto'){
        $('.rate-summary-content').css('top', stop + 'px');
      }
    }
    if($('.rs-book-with-conf') && RateSummaryCurrent.stickyOptsBookWithConfidence.stick){
      let btop = RateSummaryCurrent.stickyOptsBookWithConfidence.stick.css.top - (height + 15);
      if(!$('.rs-book-with-conf').is('.stick') && $('.rate-summary-content').css('top') !== 'auto'){
        $('.rs-book-with-conf').css('top',  (RateSummaryCurrent.opts.stick.top - (height + 15)) + 'px');
      }
      RateSummaryCurrent.stickyOptsBookWithConfidence.stick.css.top = btop;
      if($('.rs-book-with-conf').is('.stick') && $('.rs-book-with-conf').css('top') !== 'auto'){
        $('.rs-book-with-conf').css('top', btop + 'px');
      }
    }
    this.updateToataAmountAftertax(addedPackage);

  }


  populateModelData(packageAddons, price, AEM = false) {
    let packageSummaryDetail = $("#packageSummaryDetail");

    $('.modal-title.headline-c', packageSummaryDetail).html(packageAddons.comments.category);
    $('.modal-subtitle', packageSummaryDetail).text($('<div/>',{html: packageAddons.comments.title.toLowerCase()}).text());
    $('.package-description.modal-section > span', packageSummaryDetail).html($('<div/>',{html: packageAddons.comments.description}).text());
    $('.package-nights-header > li', packageSummaryDetail).not('.breakdown-label').remove('li');
    $('.package-nights-value > li', packageSummaryDetail).not('.breakdown-label').remove('li');
    let toatalAmountAftertax = 0;
    $.each(packageAddons.prices, (counter, price) => {

      $('.package-nights-header', packageSummaryDetail).append(`
    <li class="">${formatDateForPrinting(price.effectiveDate, 'weekdayCompact')}</li>
    `);
      $('.package-nights-value', packageSummaryDetail).append(`
    <li class="">${getCurrencyMapping(price.currencyCode, true)  + parseFloat(AEM ? price.amountAfterTax : this.totalPakageNightPriceQuantity(packageAddons,price.amountAfterTax)).toFixed(2) + ' ' + price.currencyCode}</li>
    `);
      toatalAmountAftertax += parseFloat(AEM ? price.amountAfterTax : this.totalPakageNightPriceQuantity(packageAddons,price.amountAfterTax));

    });

    $('.cash-total', packageSummaryDetail).html(`<span class="currency-symbol">${getCurrencyMapping(price.currencyCode,true)}</span>${parseFloat(toatalAmountAftertax).toFixed(2)}<span class="currency-code">${' ' + price.currencyCode}</span>`);

  }

  goToBooking() {
    // goToBook(req_room_type, req_rate_plan, req_rate_cc, window.req_auto_enroll, window.req_qual_points,
    //   '', window.req_after_tax, req_roomImg);
    let bookdata = ReservationHandler.getBookData();
    goToBook(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.totatax, '', false, bookdata.directBillSupported);
  }

  handlePackagesOverview() {
    if ($_PAGE_.is('.packages-page')) {
      $(".rs-book-with-conf").remove();
      this.packagesoverview = document.querySelector(".packages-overview");
      this.multiroomalert = document.querySelector(".multiroom-alert");
      this.packagesParent = $('.packages-add-on__listing').parent();
      this.rateSummaryParent = $('.res-rate-summary').parent();

      this.rateSummaryParent.eq(0).css({"z-index": 2, "postion": "relative"});
      new MutationObserver(function(){
        if($('#packageSummaryDetail')[0].style.display != 'none'){
          $('.res-rate-summary').parent()[0].style.zIndex = "";
        } else if($('#packageSummaryDetail')[0].style.display == 'none'){
          $('.res-rate-summary').parent()[0].style.zIndex = "2";
        }
      }).observe($('#packageSummaryDetail')[0], { attributes: true, childList: true });

      if (!isDesktopWidth()){
        if (this.rateSummaryParent.prevAll().index(this.packagesoverview != -1 && $(".packages-overview").length)){
          this.packagesParent[0].before(this.packagesoverview);
        }
        if (this.rateSummaryParent.prevAll().index(this.multiroomalert) != -1 && $(".multiroom-alert").length){
          this.packagesParent[0].before(this.multiroomalert);
        }
        $('.button-container').css("bottom","0");
      }
      window.addEventListener('resize', () => {
        if (isDesktopWidth()){
          if (this.rateSummaryParent.prevAll().index(this.packagesoverview) == -1 && $(".packages-overview").length){
            this.rateSummaryParent[0].before(this.packagesoverview);
          }
          if (this.rateSummaryParent.prevAll().index(this.multiroomalert) == -1 && $(".multiroom-alert").length){
            this.rateSummaryParent[0].before(this.multiroomalert);
          }
          if (isTabletWidth()) {
            $('.button-container').css("bottom",-2000);
          }
        } else if (!isDesktopWidth()){
          if (this.rateSummaryParent.nextAll().index(this.packagesoverview) == -1 && $(".packages-overview").length){
            this.packagesParent[0].before(this.packagesoverview);
          }
          if (this.rateSummaryParent.nextAll().index(this.multiroomalert) == -1 && $(".multiroom-alert").length){
            this.packagesParent[0].before(this.multiroomalert);
          }
        }
        this.handlePackagesFooter();
      });
      window.addEventListener('scroll', () => {
        this.handlePackagesFooter();
      });
    }
  }

  returnOverviewButton() {
    $('.button-container').css("bottom","");
    document.querySelector('.package-booking-button').after(document.querySelector(".button-container"));
    $('.button-container').removeClass("button-container__adjusted-postion");
    $('.button-container').css("width", "");
    $('.res-rate-info').css("padding-bottom","");
    $('.rate-summary-content.stick').css("padding-bottom","");

  }

  handlePackagesFooter() {
    if (isDesktopWidth()) {
      $('footer').css({"margin-top":"100px"});
      if (document.documentElement.scrollTop == 0 && $('.package-booking-button').nextAll()[0] != $(".button-container")[0]){
        this.returnOverviewButton();
        $('.rate-summary-content').attr('style', '');
      } else if (document.documentElement.scrollTop != 0 && $('.package-booking-button').nextAll()[0] == $(".button-container")[0]) {
        document.querySelector('.res-rate-currency-conversion').after(document.querySelector(".button-container"));
        $('.button-container').addClass("button-container__adjusted-postion");
        $('.button-container').css( "width", $(".res-rate-summary.container-background").width());
        if($('.rate-summary-content').css('z-index') == 5){
          $('.button-container').css( "width", $(".rate-summary-content").css('width'));
        }
        $('.res-rate-info').css("padding-bottom","0");
      } else if (document.documentElement.scrollTop != 0) {
        $('.button-container').css( "width", $(".res-rate-summary.container-background").width());
        $('.rate-summary-content.stick').css("padding-bottom","0");
      }
    } else if (isTabletWidth() || isMobileWidth()) {
      if ($('.package-booking-button').nextAll()[0] != $(".button-container")[0]){
        this.returnOverviewButton();
      }
      $('footer').css({"margin-top":"116px"});
      if ($(document).scrollTop() + window.innerHeight < $('footer').offset().top) {
        $('.button-container').css("bottom","0");
      } else {
        $('.button-container').css("bottom",window.innerHeight - document.getElementsByTagName("footer")[0].getBoundingClientRect().top);
      }
    }
  }

  alignPricesToPackagesOnModal(){
    if ($("#rateSummaryDetail")) {
      $("#rateSummaryDetail").on('shown.bs.modal', () => {
        $(".rd.package-nights-value li").each(function(chosenPackage){
          $(this).css({"height" : $(".rd.package-nights-header li")[chosenPackage].offsetHeight});
        });
      });
    }
  }

  populatePackageOnBooking(){

    let addedPackage = getSessionStorage('added-package') || {};
    $.each(addedPackage, (counter, addedpackage)=>{
      if(this._data.packages.map((a)=>a.comments.title).indexOf(addedpackage.packageAddons.comments.title) >= 0){
        this.addPackageToBooking(addedpackage.packageAddons,addedpackage.price, addedpackage.qty);
      }
    });
    if(Object.keys(addedPackage).length > 0 &&  (!$_PAGE_.is('.packages-page') && $_PAGE_.is('.booking-page'))){
      this.backToPackagePage();
    }
  }

  populateServiceFromRetrive(){

    if(reservationDataFromAEM.packages){
      if(reservationDataFromAEM.packages.match(/^[\{\[]/)){
        reservationDataFromAEM.packages = JSON.parse(reservationDataFromAEM.packages);
      }
      let addedPackage = reservationDataFromAEM.packages || {};
      $.each(addedPackage, (counter, addedpackage)=>{
        this.addPackagefromAEM(addedpackage,addedpackage.prices[0], addedpackage.quantity);
      });
    }
  }

  clearPackage(){
  if (!window.location.href.includes('wyndham-rewards/auth-after-sso')) {
    setSessionStorage('added-package',{},true);
    }
  }

  handleMultiRoomAlert(){
    if(this.criteria && this.criteria.rooms && parseInt(this.criteria.rooms) > 1){
      $('.multiroom-alert').show();
    }else{
      $('.multiroom-alert').hide();
    }
  }

}

let packageAddon = new PackagesAddons();
export default packageAddon;

if ($_PAGE_.is('.packages-page') || $_PAGE_.is('.booking-page')) {
  packageAddon.onGetProfileSignOut();
}else if(!$_PAGE_.is('.rooms-rates-page') && !window.location.href.includes('wyndham-rewards/auth-after-sso')){
  packageAddon.clearPackage();
}

if(!$_PAGE_.is('.packages-page') && !$_PAGE_.is('.booking-page')){
  packageAddon.populateServiceFromRetrive();
}


