import {
  _LOCALE_
} from './vars.js';
import {
  CookieHandler,
  _isNotNull,
  _isNull
  } from './utils.js';

class GoogleAPI {
  constructor() {
    this.initializeData();
  }

  initializeData(restrictGoogle) {
    this.map = null;
    this.geoCoder = null;
    this.placesService = null;
    this.autoCompleteService = null;
    this.setMapStyle();
    this.loadGoogleCalls = restrictGoogle || true;
    this.isProxy = (CookieHandler.readCookie('country_code') && CookieHandler.readCookie('country_code').toString() === 'CN') ? true : false;
    let deferred = $.Deferred();
    this.loadedPromise = deferred.promise();
    window.restrictgoogleapi = (window.restrictgoogleapi && window.restrictgoogleapi.indexOf(',') > 0) ? window.restrictgoogleapi.split(',') : window.restrictgoogleapi;
    let currentPage = window.currentPagePath.split('/');
    currentPage = currentPage[currentPage.length - 1];
    if(!restrictGoogle && window.restrictgoogleapi && window.restrictgoogleapi.length > 0) {
      for(let i = 0;i < window.restrictgoogleapi.length;i++) {
        if (currentPage == window.restrictgoogleapi[i]) {
          this.loadGoogleCalls = false;
        }
      }
    }
    if(!this.isProxy && this.loadGoogleCalls) {
      $.ajax({
        dataType: 'script',
        cache: true,
        url: this.buildGoogleMapsScriptUrl()
      }).then(() => {
        this.map = document.createElement('div');
        this.placesService = new window.google.maps.places.PlacesService(this.map);
        this.autoCompleteService = new window.google.maps.places.AutocompleteService();
        this.geoCoder = new window.google.maps.Geocoder();
        deferred.resolve();
        document.dispatchEvent(new CustomEvent('google-map-initialized'));
      });
    } else {
      deferred.resolve();
    }
  }

  buildGoogleMapsScriptUrl() {
    let url = 'https://maps.googleapis.com/maps/api/js',
      locale = this.getLocale(),
      params = {
        key: window.gKey,
        libraries: 'places',
        language: locale.language,
        region: locale.region,
        channel: window.gChannel
      };
    return url + '?' + $.param(params);
  }

  getLocale() {
    this.locale = _LOCALE_;

    if (this.locale === 'es-la') {
      this.locale = 'es-mx';
    }

    return {
      locale: this.locale,
      language: this.locale.substring(0, 2),
      region: this.locale.substring(3, 5).toUpperCase()
    };
  }

  getLocationByLevel(addressComponents, searchType, isShortName) {
    isShortName = typeof isShortName !== 'undefined' ? isShortName : false;
    if (typeof addressComponents !== 'undefined') {
      for (let i = 0; i < addressComponents.length; i++) {
        if(_.contains(addressComponents[i].types, searchType)) {
          return isShortName ? addressComponents[i]['short_name'] : addressComponents[i]['long_name'];
        }
      }
    }
  }

  getLocationDetailsFromGeoCoding(address) {
    let place = null,
      deferred = $.Deferred();

    this.geoCoding(address).then((places) => {
      if (places.length > 0) {
        place = places[0]; // Get always the first value
      }

      if (place != null) {
        let location = this.prepareLocationObject(place);
        deferred.resolve(location);
      } else {
        deferred.reject();
      }
    }, () => {
      console.log('Something went wrong location details not found');
      deferred.reject();
    });

    return deferred;
  }

  getLocationDetailsFromPlacesSearch(address, fullDetails) {
    let place = null,
      deferred = $.Deferred();

    this.placesSearch(address).then((places) => {
      if (places.length > 0) {
        place = places[0]; // Get always the first value
      }

      if (place != null) {
        if (fullDetails) {
          this.getLocationFromPlace(place.place_id).then((l) => {
            deferred.resolve(l);
          }, () => {
            deferred.reject();
          });
        } else {
          let location = this.prepareLocationObject(place);
          deferred.resolve(location);
        }
      } else {
        deferred.reject();
      }
    }, () => {
      console.log('Something went wrong location details not found');
      deferred.reject();
    });

    return deferred;
  }

  getLocationDetailsFromAutocomplete(address, fullDetails) {
    let place = null,
      deferred = $.Deferred();

    if (!this.loadGoogleCalls) {
      console.log('Cannot get location using googleAPI due is restricted in this page');
      deferred.reject();
      return deferred;
    }
    if(_isNull(address)) {
      console.log('Something went wrong Autocomplete details not found');
      deferred.reject();
    } else {
      this.placeAutoComplete(address, 'all').then((places) => {
        if (places.length > 0) {
          place = places[0]; // Get always the first value
        }

        if (place != null) {
          if (fullDetails) {
            this.getLocationFromPlace(place.place_id).then((l) => {
              deferred.resolve(l);
            }, () => {
              deferred.reject();
            });
          } else {
            let location = this.prepareLocationObject(place);
            deferred.resolve(location);
          }
        } else {
          deferred.reject();
        }
      }, () => {
        console.log('Something went wrong Autocomplete details not found');
        deferred.reject();
      });
    }

    return deferred;
  }

  getLocationDetailsFromReverseGeoCoding(lat, lng) {
    let place = null,
      deferred = $.Deferred();

    this.reverseGeoCoding(lat, lng).then((places) => {
      if (places.length > 0) {
        place = places[0]; // Get always the first value
      }

      if (place != null) {
        let location = this.prepareLocationObject(place);
        deferred.resolve(location);
      } else {
        deferred.reject();
      }
    }, () => {
      console.log('Something went wrong location details not found');
      deferred.reject();
    });

    return deferred;
  }

  prepareLocationObject(place) {
    if(window.digitalData && window.digitalData.search && window.digitalData.search.searchInfo)
      window.digitalData.search.searchInfo.searchChannel = (place.types.length > 1) ? place.types.join('|').replace(/_/g,' ') : ((place.types.length == 1) ? place.types[0].replace(/_/g,' ') : '');
    let location = {};
    location.location =  place.formatted_address || '';
    location.country = this.getLocationByLevel(place.address_components, 'country') || '';
    location.countryCode = this.getLocationByLevel(place.address_components, 'country', true) || '';
    location.city = this.getLocationByLevel(place.address_components, 'locality') || '';
    location.state = this.getLocationByLevel(place.address_components, 'administrative_area_level_1') || '';
    location.stateCode = this.getLocationByLevel(place.address_components, 'administrative_area_level_1', true) || '';
    location.latitude = this.getLocationValue(place, 'lat') || '',
    location.longitude = this.getLocationValue(place, 'lng') || '',
    location.brand = window.brand;
    location.name = (typeof place.formatted_address !== 'undefined') ? place.formatted_address.split(',')[0] : '';
    location.loc = place.place_id || '';
    return location;
  }

  getLocationValue(place, value) {
    if (_isNotNull(place) &&
        _isNotNull(place.geometry) &&
        _isNotNull(place.geometry.location)) {
      if (typeof place.geometry.location[value] === 'function') {
        return place.geometry.location[value].call();
      } else if (typeof place.geometry.location[value] !== 'undefined') {
        return place.geometry.location[value];
      }
    }
    return '';
  }

  getLocationFromPlace(placeId) {
    let location = {},
      deferred = $.Deferred(),
      place = null;

    this.geoCodingByPlaceId(placeId).then((places) => {
      if (places.length > 0) {
        place = places[0]; // Get always the first value
      }
      if (place != null) {
        location = this.prepareLocationObject(place);
        deferred.resolve(location);
      } else {
        deferred.reject();
      }
    }, () => {
      console.log('Something went wrong location details not found');
      deferred.reject();
    });

    return deferred.promise();
  }

  placeAutoComplete(searchTerm, type) {
    let deferred = $.Deferred(), locale = this.getLocale();

    this.loadedPromise.then(() => {
      if(!this.isProxy){
        let requestObj = {
           input: searchTerm,
           types: [type]
        };
        if (type === 'all') {
          delete requestObj.types;
        }
        this.autoCompleteService.getPlacePredictions(requestObj, (predictions, status) => {
          if (status == window.google.maps.places.PlacesServiceStatus.OK) {
            deferred.resolve(predictions);
          } else {
            deferred.resolve([]);
          }
        });
      } else {
        let typeTerm = '';
        if (type !== 'all'){
          typeTerm = '&types=' + type;
        }
        $.ajax({
          url: '/WHGServices/LocationServices/autocomplete?input=' + searchTerm + typeTerm + '&language=' + locale.language,
          type: 'GET',
          success: (results, status) => {
            if (results.status == 'OK') {
              deferred.resolve(results.predictions);
            }
          },
          error: (response) => {
            deferred.resolve([]);
          }
        });
      }
    });

    return deferred.promise();
  }

  placeAutoCompleteTypes(searchTerm, types) {
    let deferred = $.Deferred();

    $.when.apply(null, $.map(types, (type) => {
      return this.placeAutoComplete(searchTerm, type);
    })).then((...args) => {
      let results = {};

      for (let i = 0; i < types.length; i++) {
        results[types[i]] = args[i];
      }

      deferred.resolve(results);
    });

    return deferred.promise();
  }

  placeDetails(placeId) {
    let deferred = $.Deferred();

    this.loadedPromise.then(() => {
      this.placesService.getDetails({
        placeId: placeId
      }, (place, status) => {
        if (status == window.google.maps.places.PlacesServiceStatus.OK) {
          deferred.resolve(place);
        } else {
          deferred.reject();
        }
      });
    });

    return deferred.promise();
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  geoCoding(address) {
    let deferred = $.Deferred(), locale = this.getLocale();

    this.loadedPromise.then(() => {
      if(!this.isProxy){
        this.geoCoder.geocode({
          'address': address
        }, (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {
            deferred.resolve(results);
          } else {
            deferred.reject();
          }
        });
      } else {
        $.ajax({
          url: '/WHGServices/LocationServices/geocode?address=' + address + '&language=' + locale.language,
          type: 'GET',
          success: (results, status) => {
            if (results.status == 'OK') {
              deferred.resolve(results.results);
            }
          },
          error: (response) => {
            deferred.reject();
          }
        });
      }
    });

    return deferred.promise();
  }

  geoCodingByPlaceId(placeId) {
    let deferred = $.Deferred(), locale = this.getLocale();

    this.loadedPromise.then(() => {
      if(!this.isProxy){
        this.geoCoder.geocode({'placeId': placeId}, (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {
            deferred.resolve(results);
          } else {
            deferred.reject();
          }
        });
      } else {
        $.ajax({
          url: '/WHGServices/LocationServices/geocode?place_id=' + placeId + '&language=' + locale.language,
          type: 'GET',
          success: (results, status) => {
            if (results.status == 'OK') {
              deferred.resolve(results.results);
            }
          },
          error: (response) => {
            deferred.reject();
          }
        });
      }
    });

    return deferred.promise();
  }

  reverseGeoCoding(lat, lng) {
    let deferred = $.Deferred(), locale = this.getLocale(),
      latLng = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      };

    this.loadedPromise.then(() => {
      if(!this.isProxy){
        this.geoCoder.geocode({
          'location': latLng
        }, (results, status) => {
          if (status == window.google.maps.GeocoderStatus.OK) {
            deferred.resolve(results);
          } else {
            deferred.reject();
          }
        });
      } else {
        $.ajax({
          url: '/WHGServices/LocationServices/geocode?latlng=' + parseFloat(lat) + ',' + parseFloat(lng) + '&language=' + locale.language,
          type: 'GET',
          success: (results, status) => {
            if (results.status == 'OK') {
              deferred.resolve(results.results);
            }
          },
          error: (response) => {
            deferred.reject();
          }
        });
      }
    });

    return deferred.promise();
  }

  placesSearch(address) {
    let deferred = $.Deferred(), locale = this.getLocale();

    this.loadedPromise.then(() => {
      if(!this.isProxy){
        this.placesService.textSearch({
          'query': address,
          'types': ['locality']
        }, (results, status) => {
          if (status == window.google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
            deferred.resolve(results);
          } else {
            this.placesService.textSearch({
              'query': address,
              'types': ['sublocality']
            }, (results, status) => {
              if (status == window.google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                deferred.resolve(results);
              } else {
                this.placesService.textSearch({
                  'query': address
                }, (results, status) => {
                  if (status == window.google.maps.places.PlacesServiceStatus.OK && results && results.length > 0) {
                    deferred.resolve(results);
                  } else {
                    deferred.reject();
                  }
                });
              }
            });
          }
        });
      } else {
        $.ajax({
          url: '/WHGServices/LocationServices/placequery?query=' + address + '&types=locality&language=' + locale.language,
          type: 'GET',
          success: (results) => {
            if (results.status == 'OK' && results.results && results.results.length > 0) {
              deferred.resolve(results.results);
            } else {
              $.ajax({
                url: '/WHGServices/LocationServices/placequery?query=' + address + '&types=sublocality&language=' + locale.language,
                type: 'GET',
                success: (results) => {
                  if (results.status == 'OK' && results.results && results.results.length > 0) {
                    deferred.resolve(results.results);
                  } else {
                    $.ajax({
                      url: '/WHGServices/LocationServices/placequery?query=' + address + '&language=' + locale.language,
                      type: 'GET',
                      success: (results) => {
                        if (results.status == 'OK' && results.results && results.results.length > 0) {
                          deferred.resolve(results.results);
                        } else {
                          deferred.reject();
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    });

    return deferred.promise();
  }

  setMapStyle() {
    this.mapStyle = [{
      'elementType': 'geometry',
      'stylers': [{
        'color': '#f5f5f5'
      }]
    },
    {
      'elementType': 'geometry.fill',
      'stylers': [{
        'color': '#f4f4f4'
      }]
    },
    {
      'elementType': 'labels.icon',
      'stylers': [{
        'visibility': 'off'
      }]
    },
    {
      'elementType': 'labels.text.fill',
      'stylers': [{
        'color': '#616161'
      }]
    },
    {
      'elementType': 'labels.text.stroke',
      'stylers': [{
        'color': '#f5f5f5'
      }]
    },
    {
      'featureType': 'administrative.land_parcel',
      'elementType': 'labels.text.fill',
      'stylers': [{
        'color': '#bdbdbd'
      }]
    },
    {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [{
        'color': '#eeeeee'
      }]
    },
    {
      'featureType': 'poi',
      'elementType': 'labels.text.fill',
      'stylers': [{
        'color': '#757575'
      }]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [{
        'color': '#e5e5e5'
      }]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry.fill',
      'stylers': [{
        'color': '#d3f2cc'
      }]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.text.fill',
      'stylers': [{
        'color': '#808080'
      }]
    },
    {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [{
        'color': '#ffffff'
      }]
    },
    {
      'featureType': 'road.arterial',
      'elementType': 'labels.text.fill',
      'stylers': [{
        'color': '#757575'
      }]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry',
      'stylers': [{
        'color': '#dadada'
      }]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'geometry.fill',
      'stylers': [{
        'color': '#d0d2d3'
      }]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'labels.icon',
      'stylers': [{
        'visibility': 'simplified'
      }]
    },
    {
      'featureType': 'road.highway',
      'elementType': 'labels.text.fill',
      'stylers': [{
        'color': '#616161'
      }]
    },
    {
      'featureType': 'road.local',
      'elementType': 'labels.text.fill',
      'stylers': [{
        'color': '#9e9e9e'
      }]
    },
    {
      'featureType': 'transit.line',
      'elementType': 'geometry',
      'stylers': [{
        'color': '#e5e5e5'
      }]
    },
    {
      'featureType': 'transit.station',
      'elementType': 'geometry',
      'stylers': [{
        'color': '#eeeeee'
      }]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [{
        'color': '#c9c9c9'
      }]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry.fill',
      'stylers': [{
        'color': '#c7e5f2'
      }]
    },
    {
      'featureType': 'water',
      'elementType': 'labels.text.fill',
      'stylers': [{
        'color': '#9e9e9e'
      }]
    }
    ];
  }

  buildNearMeControl(map, addToMap) {
    let controlDiv = document.createElement('div');
    let controlUI = document.createElement('div');
    controlUI.style.backgroundColor = '#fff';
    controlUI.style.border = '2px solid #fff';
    controlUI.style.borderRadius = '3px';
    controlUI.style.boxShadow = '0 1px 1px rgba(0,0,0,.3)';
    controlUI.style.cursor = 'pointer';
    controlUI.style.marginRight = '10px';
    controlUI.style.padding = '3px';
    controlUI.style.textAlign = 'center';
    controlUI.style.className = 'map-control-near-me-container';
    controlDiv.appendChild(controlUI);

    // Set CSS for the control interior
    let controlIcon = document.createElement('div');
    controlIcon.className = 'map-control-near-me';
    controlIcon.style.height = '18px';
    controlIcon.style.width = '18px';
    controlUI.appendChild(controlIcon);

    // Setup the click event listeners: simply set the map to Chicago.
    controlUI.addEventListener('click', () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => {
          if (position) {
            map.setCenter(new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude));
          }
        });
      }
    });

    if (addToMap) {
      map.controls[window.google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
    }

    return controlDiv;
  }
}

let googleApi = new GoogleAPI();
window.Wyndham = window.Wyndham || {};
window.Wyndham.GoogleApi = googleApi;

export default googleApi;
