class CollectionOfHotels {
  constructor() {
    this.callCollectionAPI().then((res) => {
      const crrPath = location.pathname;
      const crrResourcePath = crrPath.split('.')[0];
      if(res.length >= 7 && res.length <= 15){
          const randomIds = this.getrandomFeaturedIds(res, '3');
          this.sendRandomIds(randomIds, crrResourcePath);
      } else if(res.length >= 15){
        const randomIds = this.getrandomFeaturedIds(res, '6');
        this.sendRandomIds(randomIds, crrResourcePath);
      }
    });
  }

  callCollectionAPI() {
    const locOrigin = window.location.href.split('/').slice(0, 3).join('/');
    const url = `${locOrigin}/var/whg-ecomm-responsive/mockdata/collection-of-hotels.json`;
    let df = $.Deferred();
    let cData;
      $.ajax({
        url: url,
        type: 'GET',
        success: function(data) {
            cData = df.resolve(data.data.searchProperties.properties);
            return cData;
        },
        error: function(res) {
            df.reject(res);
        }
      });

      return df.promise();
  }

  getImageTaggingData(requestObject) {
    const jsonData = JSON.stringify(requestObject);
    const url = '/bin/fetchImageData';
    let df = $.Deferred();
    let imgData;
      $.ajax({
        url: url,
        type: 'POST',
        data: jsonData,
        contentType: "application/json",
        success: function(data) {
            imgData = df.resolve(data);
            return imgData;
        },
        error: function(res) {
            df.reject(res);
        }
      });

      return df.promise();
  }

  getrandomFeaturedIds(results, n) {
      let randomFeaturedIds = [];
      for (let i = 0; i < n; i++) {
        const idx = Math.floor(Math.random() * results.length);
        randomFeaturedIds.push(results[idx].id);
        results.splice(idx, 1);
      }
      return randomFeaturedIds;
  }

  sendRandomIds(randomIds, crrResourcePath) {
      const fcCdardData = JSON.stringify({propertyId: randomIds, currentResourcePath: crrResourcePath});
      $.ajax({
        type: 'POST',
        url: '/bin/saveFeatureCardDeafaultData',
        data: fcCdardData,
        contentType: "application/json",       
      });
  }

  renderFcDynamicContent(ids, response) {
    let dynamicCollection = [];
    ids.forEach((id) => {
      dynamicCollection.push(response.filter((item) => item.id === id)[0]);
    });
    return dynamicCollection;
  }
}

export default new CollectionOfHotels();
